import React, {useEffect} from "react";
import Header from "./components/header";
import { zoneData } from "../utils/eventsData";
import { images } from "../utils/zoneGalleryImages";
import { SliderComponent } from "./components/sliderComponet";
import SidebarComponent from "./components/navbar";
import Footer from "./components/footer";
import NavbarDesktop from "./components/navbar/navbarDesktop";
import { useNavigate } from "react-router-dom";
import UpcomingEvents from "./pages/events/upcomingEvents";

const Homepage = () => {
    const addScriptTag = () => {
        const script = document.createElement('script');
        script.src = 'https://s3-eu-west-1.amazonaws.com/xceed-widget/2019-version/dist/loader.js';
        // Or just set the javascript on the script tag by adding innerText and type arts
        document.head.appendChild(script)
    }
    useEffect(()=>{
        addScriptTag()
    },[])
  const navigate = useNavigate();
  return (
    <div className="homepage__components">
      <div className="navbarDesktop__gallery">
        <NavbarDesktop navigate={navigate} />
      </div>
      <div className="homepage__components__header">
        <Header />
      </div>
      <div className="homepage__components__navbar">
        <SidebarComponent />
      </div>
      <div className="homepage__components__homeGallery">
        <div className="homepage__components__homeGallery__content">
          <div className="homepage__components__homeGallery__content__container">
            <span className="homepage__components__homeGallery__content__container__span1">
              unforgettable nights
            </span>
            <span className="homepage__components__homeGallery__content__container__span2">
              · it's not just about the music, it's all about the experience
            </span>
          </div>
        </div>
        <div className="homepage__components__homeGallery__content__container__component">
          <SliderComponent images={images} hasActions={true} />
        </div>
      </div>
      <div className="homepage__components__upcomingEvents">
        <div className="homepage__components__upcomingEvents__textDiv">
          <div className="homepage__components__upcomingEvents__textDiv__text">
            <span>upcoming events</span>
          </div>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
            {/* <div id="xceed-widget"></div> */}
            <UpcomingEvents showHeader={false}/>

            {/*<SliderComponent*/}
          {/*  images={zoneData}*/}
          {/*  width="100%"*/}
          {/*  containerClass="eventsCard__containerClass"*/}
          {/*/>*/}
        </div>
      </div>
      <div className="zoneData__container">
        <Footer />
      </div>
    </div>
  );
};

export default Homepage;
