import React from "react";
import johndoe from "../../../assets/images/john-doe.jpg";

const Profile = ({ currentUser }) => {
  return (
    <div className="navbar__container__right">
      <div className="navbar_image">
        <img className="navbar_img" src={johndoe} alt="" />
      </div>
      <div className="navbar__container__nameAndJobs">
        <div className="navbar__container__nameAndJobs__name">
          {currentUser?.firstName} {currentUser?.lastName}
        </div>
        <div className="navbar__container__nameAndJobs__job">
          {currentUser?.roleId}
        </div>
      </div>
    </div>
  );
};

export default Profile;
