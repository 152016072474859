import React from "react";
import ButtonComponent from "../../../../components/button";
import StepTableComponent from "./StepTableComponent";
import StepCardComponent from "./StepCardComponent";
import confirmedIcon from "../../../../zone-assets/icons/confirmedIcon.svg";
import { useNavigate, useParams } from "react-router-dom";

const Step5 = ({ handleContinue }) => {
  const navigate = useNavigate();
  const params = useParams();
  const handleClickButton = () => {
    const id = "confirmation";
    navigate(`/booking/${id}`);
  };
  return (
    <div className="step4__container">
      <div className="step4__container__items">
        <div className="step4__container__items__left">
          <div className="step4__container__items__left__eventName">
            <StepCardComponent />
          </div>
        </div>
        <div className="step4__container__items__right">
          <div className="step4__container__items__right__confirmCard">
            <img src={confirmedIcon} alt="" />
            payment confirmed
          </div>
          <div className="step4__container__items__right__confirmEmail">
            <span>
              confirmation email was sent to you containing the details
            </span>
          </div>
          <div className="step4__container__items__right__table">
            <StepTableComponent />
          </div>
        </div>
      </div>
      <div className="step4__container__button">
        <ButtonComponent
          label="confirm and pay"
          className="bookingPage__steper__button"
          // onClick={handleContinue}
          onClick={handleContinue}
        />
      </div>
    </div>
  );
};

export default Step5;
