import React from "react";

function PhotoUploader({ selectedPhoto }) {
  return (
    <div>
      {selectedPhoto && (
        <div className="uploadPhoto__container">
          <div className="uploadPhoto__container__image">
            <img
              src={selectedPhoto}
              alt="Uploaded"
              className="uploadPhoto__container__image__img"
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default PhotoUploader;
