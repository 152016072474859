import React from "react";
import ButtonComponent from "../../../../components/button";
import StepCardComponent from "./StepCardComponent";
import StepTableComponent from "./StepTableComponent";

const Step4 = ({ handleContinue }) => {
  return (
    <div className="step4__container">
      <div className="step4__container__alls">
        <div className="step4__container__items">
          <div className="step4__container__items__left">
            <div className="step4__container__items__left__eventName">
              <StepCardComponent />
            </div>
          </div>
          <div className="step4__container__items__right">
            <div className="step4__container__items__right__table">
              <StepTableComponent />
            </div>
          </div>
        </div>
      </div>
      <div className="step4__container__button">
        <ButtonComponent
          label="confirm and pay"
          className="bookingPage__steper__button"
          onClick={handleContinue}
        />
      </div>
    </div>
  );
};

export default Step4;
