import React from "react";
import zoneMap from "../../../../zone-assets/images/HartaZone1.png";
import ButtonComponent from "../../../../components/button";
import DropdownComponent from "../../../../components/dropdown";
import { useRecoilState } from "recoil";
import { bookingInformations } from "../../../../utils/atoms";
import { events, tables } from "./staticData";

const Step2 = (props) => {
  const {
    handleContinue,
    setFieldValue,
    values,
    errors,
    touched,
  } = props;
  const [bookingInfos, setBookingInfos] = useRecoilState(bookingInformations)
  return (
    <div className="step2__container">
      <div className="step2__container__img">
        <img src={zoneMap} alt="" />
      </div>
      <div className="step2__container__right">
        <div className="step2__container__right__items">
          <div className="step2__container__right__items__dropDowns">
            <DropdownComponent
              className="navbar_button"
              dropdownManual="navbar__dropdown"
              width={150}
              placeholder="zone"
              options={events}
              onChange={(value) => {
                setFieldValue("zone.zone", value);
                setBookingInfos({
                  ...bookingInfos,
                  zone: value
                })
              }}
              defaultValue={values?.zone}
              errors={errors?.zone}
              touched={touched?.zone}
            />
            <DropdownComponent
              className="navbar_button"
              dropdownManual="navbar__dropdown"
              width={150}
              placeholder="table"
              options={tables}
              onChange={(value) => {
                setFieldValue("zone.table", value);
                setBookingInfos({
                  ...bookingInfos,
                  table: value
                })
              }}
              defaultValue={values?.table}
              errors={errors?.table}
              touched={touched?.table}
            />
          </div>
          <div className="step2__icons__all">
            <div className="step2__icons">
              <div className="step2__icons__firstItem">
                <div className="step2__cube"></div>
                <span>SITTING TABLE</span>
              </div>
              <div className="step2__icons__firstItem">
                <div style={{ display: "flex", gap: "9px" }}>
                  <div className="step2__lines">|</div>
                  <div className="step2__lines2">|</div>
                </div>
                <span>STANDING TABLE</span>
              </div>
              <div className="step2__icons__firstItem">
                <div style={{ display: "flex" }}>
                  <div className="step2__rounder"></div>
                </div>
                <span>STANDING TABLE</span>
              </div>
            </div>
            <div className="step2__colors">
              <div className="step2__colors__first">
                <div className="step2__colors__first__first"></div>
                <span>ZONA Z(1st FLOOR)</span>
              </div>
              <div className="step2__colors__second">
                <div className="step2__colors__second__second"></div>
                <span>ZONA Z(2nd FLOOR)</span>
              </div>
              <div className="step2__colors__third">
                <div className="step2__colors__third__third"></div>
                <span>ZONA Z(2nd FLOOR)</span>
              </div>
              <div className="step2__colors__fourth">
                <div className="step2__colors__fourth__fourth"></div>
                <span>ZONA Z(2nd FLOOR)</span>
              </div>
              <div className="step2__colors__fifth">
                <div className="step2__colors__fifth__fifth"></div>
                <span>ZONA Z(2nd FLOOR)</span>
              </div>
            </div>
          </div>
        </div>
        <div className="step2__container__button">
          <ButtonComponent
            label="continue"
            className="bookingPage__steper__button"
            onClick={handleContinue}
            type="submit"
          />
        </div>
      </div>
    </div>
  );
};

export default Step2;
