import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
// import devolliLogoSmall from "../../../../assets/images/devolliLogoSmall.svg";
import zoneLogoHeader from "../../../../zone-assets/images/zoneDrawerLogo.svg";
import settings from "../../../../assets/icons/settingsIcon.svg";
import zoneLogoutIcon from "../../../../zone-assets/icons/zoneLogoutIcon.svg";
import { drawerItems } from "./DrawerAdminItems";
import { userState } from "../../../../utils/atoms";
import { useSetRecoilState } from "recoil";

const DrawerComponent = ({ activeTab, setActiveTab }) => {
  const navigate = useNavigate();
  const setUser = useSetRecoilState(userState);
  useEffect(() => {
    const currentTab = window.location.pathname.split("/")[1];
    setActiveTab(currentTab);
  }, [setActiveTab]);

  const handleTabs = (drawerTab) => {
    navigate(`/${drawerTab}`);
    setActiveTab(drawerTab);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    setUser({
      firstName: "",
      lastName: "",
      id: null,
    });
    navigate("/");
  };

  return (
    <div className="drawer__container">
      <div className="drawer__container__logo">
        <img src={zoneLogoHeader} alt="" />
      </div>
      <div className="drawer__container__items">
        {drawerItems.map((items, index) => (
          <div
            key={index}
            onClick={() => handleTabs(items.tab)}
            className={`body-left-box ${
              activeTab === items.tab ? "active" : ""
            }`}
          >
            <div className="body-left-items">
              <img
                src={items.image}
                className={`${
                  activeTab === items.tab ? "svgActive" : "imageNavbar"
                }`}
                alt=""
              />
              {items.name}
              <div
                className={`hoverBox ${
                  activeTab === items.tab ? "active" : ""
                }`}
              ></div>
            </div>
          </div>
        ))}
      </div>
      <div className="border_bottom_container">
        <div className="border_bottom"></div>
      </div>
      <div className="drawer__container__settings">
        <span
          className="drawer__container__settingsLogOut"
          onClick={handleLogout}
        >
          <img src={zoneLogoutIcon} alt="" />
          Log Out
        </span>
      </div>
    </div>
  );
};

export default DrawerComponent;
