import { DatePicker } from "antd";
import React from "react";

const CustomDatePicker = (props) => {
  const {
    className = "",
    onChange,
    showTime ,
    disabledDate,
    errors,
    touched,
  } = props;
  return (
    <div className={className}>
      <DatePicker
        onChange={onChange}
        disabledDate={disabledDate}
        showTime={showTime}
      />
      {errors && touched && (
        <span className="input_component__error">{errors}</span>
      )}
    </div>
  );
};

export default CustomDatePicker;
