import React, { useEffect, useState } from "react";
import ButtonComponent from "../button";
import InputComponent from "../input";
import { Formik } from "formik";
import zoneIconProducts from "../../zone-assets/icons/zoneIconProducts.svg";
import axios from "../../utils/axios";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { productInitialValue, productsInitialValue } from "../../utils/atoms";
import { toast } from "react-toastify";
import productValidationSchema from "../../validationSchemas/productInput";
import { InputNumber } from "antd";

const CreateProducts = ({ setIsModalOpen }) => {
  const [product, setProduct] = useRecoilState(productInitialValue);
  const [products, setProducts] = useRecoilState(productInitialValue);

  useEffect(() => {
    return () => {
      setProduct({
        productName: "",
        productPrice: "",
      });
    };
  }, []);

  const handleSubmit = async (values) => {
    if (product?.id) {
      // let obj = { ...values, locationId: 1 };
      axios
        .put(`/locations/${product?.id}`, values)
        .then((res) => {
          setProduct(product);
          toast.success("Location successfully updated.", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          const arr = products.map((i) => {
            if (product?.id === i.id) {
              return values;
            } else {
              return i;
            }
          });
          setProducts(arr);
        })
        .catch((err) => {
          toast.error(`${err?.response?.data?.message}`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        });
    } else {
      let obj = {
        ...values,
      };
      axios
        .post("/locations", obj)
        .then((res) => {
          setProduct([...products, obj]);
          toast.success("New location has been successfully created.", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        })
        .catch((err) => {
          toast.error("Location failed to created, please try again later.", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        });
    }
  };

  return (
    <>
      <Formik
        initialValues={product}
        validationSchema={productValidationSchema}
        onSubmit={(values, { resetForm }) => {
          handleSubmit(values);
          resetForm({ values: "" });
          setIsModalOpen(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <>
            <div className="createProduct__header">
              <img className="svgActive" src={zoneIconProducts} alt="" />
              {product.id ? "Edit product" : "Create new product"}
            </div>
            <form
              onSubmit={handleSubmit}
              autoComplete="new-password"
              noValidate
            >
              <div className="createProduct__container">
                <div className="createProduct__container__inputs">
                  <InputComponent
                    className="createProduct__productName"
                    label="Product Name"
                    name="productName"
                    onChange={(e) =>
                      setFieldValue("productName", e.target.value)
                    }
                    errors={errors.productName}
                    touched={touched.productName}
                    values={values.productName}
                  />
                  <div className="input_component">
                    <label>Product Price</label>
                    <InputNumber
                      addonAfter="€"
                      defaultValue={values?.productPrice}
                      className="custom-price-input"
                      min={1}
                      onChange={(value) => setFieldValue("productPrice", value)}
                    />
                    {touched?.productPrice && errors?.productPrice && (
                      <span className="input_component__error">
                        {errors?.productPrice}
                      </span>
                    )}
                  </div>
                  {/* <InputComponent
                    className="createProduct__productName"
                    label="Product Price"
                    name="productPrice"
                    onChange={(e) =>
                      setFieldValue("productPrice", e.target.value)
                    }
                    errors={errors.productPrice}
                    touched={touched.productPrice}
                    values={values.productPrice}
                  /> */}
                </div>
                <div className="createProduct__container__buttons">
                  <ButtonComponent
                    className="createProduct__container__button1"
                    label="Cancel"
                    type="button"
                    onClick={() => setIsModalOpen(false)}
                  />
                  <ButtonComponent
                    className="createProduct__container__button2"
                    label="Save Product"
                    name="save"
                    type="submit"
                  />
                </div>
              </div>
            </form>
          </>
        )}
      </Formik>
    </>
  );
};

export default CreateProducts;
