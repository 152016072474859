import React from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import ButtonComponent from "../../components/button";
import InputComponent from "../../components/input";
// import largeLogo from "../../assets/images/devolliLogoLarge.svg";
// import smallLogo from "../../zone-assets/images/devolliLogoSmall.svg";
import smallLogo from "../../zone-assets//icons/zoneLogo.svg";
import axios from "axios";
import { toast } from "react-toastify";
import PasswordInput from "../../components/input/passworInput";
import { useNavigate } from "react-router-dom";
import { loginSchema } from "../../validationSchemas/loginSchema";

const Login = () => {
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: { email: "", password: "" },
    validationSchema: loginSchema,
    onSubmit: (values) => {
      handleOnSubmit(values);
    },
  });

  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleChange,
    handleSubmit,
    setSubmitting,
    setFieldValue,
  } = formik;

  const handleOnSubmit = (values, e) => {
    navigate("/dashboard");
    // axios
    //   .post("/login", values)
    //   .then((res) => {
    //     localStorage.setItem("token", res.data?.token);
    //     window.location.href = "/dashboard";
    //     toast.success("Logged in successfully", {
    //       position: "top-right",
    //       autoClose: 3000,
    //       hideProgressBar: true,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //       draggable: true,
    //       progress: undefined,
    //       theme: "light",
    //     });
    //   })
    //   .catch((err) => {
    //     setSubmitting(false);
    //     if (err.response.status === 401) {
    //       formik.setErrors({
    //         password: "Your email or password is incorrect!",
    //       });
    //     }
    //   });
  };

  return (
    <div className="login__body">
      <div className="login__body__left__inputs">
        <div className="login__body__left__inputs">
          <img src={smallLogo} alt="" />
        </div>
        <form
          className="login__body__left__inputs__form"
          onSubmit={handleSubmit}
        >
          <label className="login__body__left__inputs__form__label">
            Enter login details
          </label>
          <div className="login__body__left__form__inputs">
            <InputComponent
              name="email"
              type="text"
              placeholder="Email"
              onChange={(e) => setFieldValue("email", e.target.value)}
              value={values.email}
              errors={errors.email}
              touched={touched.email}
            />
            {/* <InputComponent
              name="password"
              type="password"
              placeholder="Password"
              onChange={(e) => setFieldValue("password", e.target.value)}
              value={values.password}
              errors={errors.password}
              touched={touched.password}
            /> */}
            <PasswordInput
              name="password"
              type="password"
              placeholder="Password"
              onChange={(e) => setFieldValue("password", e.target.value)}
              value={values.password}
              errors={errors.password}
              touched={touched.password}
            />
          </div>
          <div className="login__body__left__form__button">
            <ButtonComponent
              label="Login"
              type="submit"
              className="login_button"
              disabled={isSubmitting}
            />
          </div>
        </form>
      </div>

      {/* <img src={largeLogo} alt="" className="login__body__left__img" /> */}
      <div className="login__body__right"></div>
    </div>
  );
};
export default Login;
