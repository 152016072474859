import React, { useState } from "react";
import zoneDrawer from "../../../zone-assets/icons/zoneLogo.svg";
import Paysera from "../../../zone-assets/icons/Paysera.svg";
import facebook from "../../../zone-assets/icons/facebook.svg";
import instagram from "../../../zone-assets/icons/instagram.svg";
import { Link } from "react-router-dom";

const FooterNavbar = () => {
  const currentYear = new Date().getFullYear();
  const [isActive, setIsActive] = useState(false);

  const handleClick = () => {
    setIsActive(!isActive);
  };

  return (
    <div className="footerNavbar__container">
      <div className="footerNavbar__container__items">
        <div className="footerNavbar__container__social">
          <div className="footerNavbar__container__social__borderContainer">
            <div className="footerNavbar__container__social__borderContainer__bottomBorder"></div>
          </div>
        </div>

        <div className="footerNavbar__container__list">
          <div className="footerNavbar__container__list__imgAndList">
            <div className="footerNavbar__container__img">
              <img style={{ width: "140px" }} src={zoneDrawer} alt="Zone Logo" />
            </div>
            <div className="footerNavbar__container__socialNav">
              <div className="footerNavbar__container__ul">
                <Link to="/" style={{ transition: "color 0.3s" }} className="footerNavbar__container__ul__links">
                  <span>Home</span>
                </Link>
                <Link to="/events" style={{ transition: "color 0.3s" }} className="footerNavbar__container__ul__links">
                  <span>Events</span>
                </Link>
                <Link to="/gallery" style={{ transition: "color 0.3s" }} className="footerNavbar__container__ul__links">
                  <span>Gallery</span>
                </Link>
              </div>
            </div>
            <div className="footerNavbar__container__socialNav">
              <div className="footerNavbar__container__ul">
                <Link to="/privacy-policy" style={{ transition: "color 0.3s" }} className="footerNavbar__container__ul__links">
                  <span>Privacy Policy</span>
                </Link>
                <Link to="/refund-policy" style={{ transition: "color 0.3s" }} className="footerNavbar__container__ul__links">
                  <span>Refund Policy</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="footerNavbar__container__allRights__bottomBorder"></div>
        <div className="footerNavbar__container__allRights">
          <div className="footerNavbar__container__allRights__text">
            <span>© {currentYear} All rights Reserved - Z⭕️NE CLUB</span>
          </div>
          <div className="footerNavbar__container__paysera">
            <img style={{ width: "140px" }} src={Paysera} alt="Paysera Logo" />
          </div>
          <div className="footerNavbar__container__social__desktop">
            <a href="https://www.facebook.com/zoneclubpr" target="__blank">
              <img src={facebook} alt="Facebook" />
            </a>
            <a href="https://www.instagram.com/zoneclub/" target="__blank">
              <img src={instagram} alt="Instagram" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterNavbar;
