import React, { useState, useEffect } from "react";
import axios from "axios";
import "../../../assets/scss/components/zone-web/EventPage.scss";

const UpcomingEvents = ({ showHeader = true }) => {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    fetchEvents();
  }, []);

  const fetchEvents = async () => {
    try {
      const currentDate = new Date();
      const oneDayAgo = new Date(currentDate.getTime() - 24 * 60 * 60 * 1000);
      const oneDayAgoString = oneDayAgo.toISOString(); // Convert to ISO string for query parameter
      
      // Fetch events from the last day to minimize the amount of data fetched
      const response = await axios.get(`https://zone.tframe.de/api/events?populate=*&filters[date][$gte]=${oneDayAgoString}`, {
        headers: {
          Authorization: "Bearer 71d9802834043fb56b14b324b4166d6ef6f5718ca39296a013ae07f379f5905b0bf853eead23b25f81853b0cefa36f1d06fab1183692e26fd7f47424031b972a2a19456d6032df719d008a39448c3dedd55f61c32daaa9337f704096e06be815f14d6c31361660869340f071d2c7e18bdebd8997cb839162709406af9136c5b9"
        },
      });

      // Filter events based on the original criteria: event date + 6 hours > current time or same day
      const filteredEvents = response.data.data.filter((event) => {
        const eventDate = new Date(event.attributes.date);
        const eventDatePlus6Hours = new Date(eventDate.getTime() + 6 * 60 * 60 * 1000);
        return eventDatePlus6Hours > currentDate || eventDate.toDateString() === currentDate.toDateString();
      });

      setEvents(filteredEvents);
    } catch (error) {
      console.error("Failed to fetch events:", error);
    }
  };

  const handleButtonClick = (link) => {
    window.location.href = link;
  };

  return (
    <div className="event-container">
      {showHeader && (
        <div className="event-header">
          <h1 className="event-title">UPCOMING EVENTS</h1>
        </div>
      )}
      <div className="event-grid">
        {events
          .sort((a, b) => new Date(a.attributes.date) - new Date(b.attributes.date))
          .map((event) => {
            const imageUrl = event.attributes?.image?.data?.attributes?.url;
            const eventLink = event.attributes?.link;
            const eventLink2 = event.attributes?.link2;

            return (
              <div key={event.id} className="event-card">
                <div className="event-image-container">
                  <div className="event-image-wrapper">
                    {imageUrl ? (
                      <img
                        src={`https://zone.tframe.de${imageUrl}`}
                        alt="Artist"
                        className="event-image"
                        onClick={() => handleButtonClick(eventLink)}
                      />
                    ) : (
                      <div
                        className="no-image"
                        onClick={() => handleButtonClick(eventLink)}
                      >
                        <span>No Image Available</span>
                      </div>
                    )}
                  </div>
                </div>
                <div className="event-button-container">
                  <button
                    onClick={() => handleButtonClick(eventLink2)}
                    className="event-button"
                  >
                    <h1 className="event-button-text">Buy Tickets</h1>
                  </button>
                  <button
                    onClick={() => handleButtonClick(eventLink)}
                    className="event-button"
                  >
                    <h1 className="event-button-text">Vip Table</h1>
                  </button>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default UpcomingEvents;
