import elvanaGjata from "../zone-assets/images/elvanaGjata.png";
import yllLimani from "../zone-assets/images/yllLimani.png";
import eraIstrefi from "../zone-assets/images/eraIstrefi.png";
import dafinaZeqiri from "../zone-assets/images/dafina.png";
import allInZone1 from "../zone-assets/images/allInZone1.png";
import capitalt from "../zone-assets/images/capitalt.png";
import pmAndDagz from "../zone-assets/images/pmAndDagz.png";
import geasyAndMajk from "../zone-assets/images/geasyAndMajk.png";
import dhurataDora from "../zone-assets/images/dhurata.png";
import CAPITALBRA from "../zone-assets/images/CAPITALBRA.png";
import Luciano from "../zone-assets/images/Luciano.png";
import dayjs from "dayjs";

var isSameOrAfter = require("dayjs/plugin/isSameOrAfter");
dayjs.extend(isSameOrAfter);

const initialZoneData = [
  {
    name: "Elvana Gjata",
    date: "24.07.2023",
    day: "Monday",
    image: elvanaGjata,
    type: "live",
    ticketLink:
      "https://xceed.me/en/pristina/event/elvana-gjata--133302/channel--zone-club",
  },
  {
    name: "Yll Limani",
    date: "25.07.2023",
    day: "Tuesday",
    image: yllLimani,
    type: "live",
    ticketLink:
      "https://xceed.me/en/pristina/event/yll-limani-1--133374/channel--zone-club",
  },
  {
    name: "Era Istrefi",
    date: "26.07.2023",
    day: "Wednesday",
    image: eraIstrefi,
    type: "live",
    ticketLink:
      "https://xceed.me/pristina/event/era-istrefi--133375/channel--zone-club",
  },
  {
    name: "CAPITAL T",
    date: "27.07.2023",
    day: "Thursday",
    image: capitalt,
    type: "live",
    ticketLink:
      "https://xceed.me/pristina/event/capital-t--133588/channel--zone-club",
  },
  {
    name: "ZONE CALLS YOU ALL IN",
    date: "28.07.2023",
    day: "Friday",
    image: allInZone1,
    type: "dj",
    ticketLink:
      "https://xceed.me/en/pristina/event/zone-calls-you-all-in--133601/channel--zone-club",
  },
  {
    name: "PM & DAGZ",
    date: "29.07.2023",
    day: "Saturday",
    image: pmAndDagz,
    type: "dj",
    ticketLink:
      "https://xceed.me/en/pristina/event/pm-dagz-1--133602/channel--zone-club",
  },
  {
    name: "Dafina Zeqiri",
    date: "30.07.2023",
    day: "Sunday",
    image: dafinaZeqiri,
    type: "live",
    ticketLink:
      "https://xceed.me/pristina/event/dafina-zeqiri--133589/channel--zone-club",
  },
  {
    name: "Geasy and Majk",
    date: "31.07.2023",
    day: "Monday",
    image: geasyAndMajk,
    type: "live",
    ticketLink:
      "https://xceed.me/pristina/event/geasy-and-majk--133591/channel--zone-club",
  },
  {
    name: "Dhurata Dora",
    date: "02.08.2023",
    day: "Wednesday",
    image: dhurataDora,
    type: "live",
    ticketLink:
      "https://xceed.me/pristina/event/dhurata-dora--133590/channel--zone-club",
  },
  {
    name: "Luciano",
    date: "03.08.2023",
    day: "Thursday",
    image: Luciano,
    type: "live",
    ticketLink:
      "https://xceed.me/en/pristina/event/luciano-2--133669/channel--zone-club",
  },
  {
    name: "Capital Bra",
    date: "06.08.2023",
    day: "Sunday",
    image: CAPITALBRA,
    type: "live",
    ticketLink:
      "https://xceed.me/en/pristina/event/capital-bra--133667/channel--zone-club",
  },
];

const currentDate = dayjs();

export const zoneData = initialZoneData.filter((event) =>
  dayjs(event.date, "DD.MM.YYYY").isSameOrAfter(currentDate, "day")
);

// export const zoneData = initialZoneData.filter((item) => {
//   const [day, month, year] = item.date.split(".").map(Number);
//   const eventDate = new Date(year, month - 1, day);
//   return isTodayOrTomorrowOrDayAfter(eventDate);
// });
