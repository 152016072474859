import { useRecoilCallback, useRecoilState } from "recoil";
import axios from "../utils/axios";

export const fetchData = async (endpoint) => {
  let data = [];
  await axios
    .get(`${endpoint}`)
    .then((res) => {
      data = res.data;
    })
    .catch((err) => {
      <p>Cannot Receive Data</p>;
    });
  return data;
};
