import React from "react";
import "../../../assets/scss/components/zone-web/Refund.scss";
import SidebarComponent from "../../components/navbar";
import NavbarDesktop from "../../components/navbar/navbarDesktop";
import FooterNavbar from "../../components/footer/footerNavbar";

const refund = () => {
  return (
    <div className="refund-container">
      <div className="navbar-desktop">
        <NavbarDesktop />
        <SidebarComponent />
      </div>
      <div className="text-area">
        <h1 className="text">
          <h1 className="text-bold">Refund Policy for ZONE CLUB</h1>
          <br />
          Effective Date: JULY 2024
          <br />
          <br />
          1. Introduction
          <br />
          <br />
          At ZONE CLUB, we strive to ensure that our guests have an enjoyable
          experience. This Refund Policy outlines the conditions under which
          refunds will be provided for tickets and VIP table reservations made
          through our website (www.zoneclub.al).
          <br />
          <br />
          2. General Refund Policy
          <br />
          <br />
          Ticket Purchases: All ticket sales are final. Refunds will only be
          issued in the event of a cancellation or significant change to the
          event (e.g., change of date, venue, or headlining artist).
          <br />
          VIP Table Reservations: Reservations for VIP tables are non-refundable
          unless the event is canceled or significantly altered.
          <br />
          <br />
          3. Event Cancellations and Changes
          <br />
          <br />
          Event Cancellations: If an event is canceled, you will be entitled to
          a full refund of the ticket price and any associated fees. Refunds
          will be processed automatically to the original method of payment.
          <br />
          Event Changes: If an event is significantly changed, you will be
          notified of the change and given the option to request a refund if you
          no longer wish to attend.
          <br />
          <br />
          4. Refund Process
          <br />
          <br />
          Requesting a Refund: If you are eligible for a refund, please contact
          our customer service team at +383 46 600 444 with your order details.
          <br />
          <br />
          Processing Time: Refunds will be processed within 5 business days of
          receiving your request. The time it takes for the refund to appear in
          your account may vary depending on your payment provider.
          <br />
          <br />
          5. Non-Refundable Items
          <br />
          <br />
          No-Show: No refunds will be issued for guests who do not attend the
          event.
          <br />
          Service Fees: Service fees and any additional charges associated with
          ticket purchases are non-refundable.
          <br />
          <br />
          6. Contact Us
          <br />
          <br />
          If you have any questions or need further assistance regarding our
          Refund Policy, please contact us at:
          <br />
          <br />
          Phone: +383 46 600 444
          <br />
          <br />
          7. Changes to This Refund Policy
          <br />
          <br />
          We may update this Refund Policy from time to time. Any changes will
          be posted on this page, and the effective date will be updated
          accordingly.
        </h1>
      </div>
      <FooterNavbar />
    </div>
  );
};

export default refund;
