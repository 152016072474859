import React from "react";

const InputComponent = ({
  className,
  placeholder,
  label,
  onChange,
  values,
  touched,
  errors,
  type,
  autoComplete,
  onBlur,
  inputDivClass,
}) => {
  return (
    <div className={`input_component ${inputDivClass}`}>
      {label && <label>{label}</label>}
      <input
        className={className}
        placeholder={placeholder}
        onChange={onChange}
        type={type}
        value={values}
        autoComplete={autoComplete}
        onBlur={onBlur}
      />
      {touched && errors && (
        <span className="input_component__error">{errors}</span>
      )}
    </div>
  );
};

export default InputComponent;
