import React from "react";
import { useSetRecoilState } from "recoil";
import ButtonComponent from "../../../../components/button";
import DropdownComponent from "../../../../components/dropdown";
import { selectedDrinksState } from "../../../../utils/atoms";
import { drinks } from "./staticData";
import StepTableComponent from "./StepTableComponent";

const Step3 = (props) => {
  const { handleContinue, setFieldValue, values, touched, errors } = props;
  const setSelectedDrinks = useSetRecoilState(selectedDrinksState);
  return (
    <div className="step3__container">
      <div className="step3__container__divs">
        <div className="step3__divs">
          <div className="step3__divs__container">
            <div className="step3__container__upperText">
              <span>selcted table</span>
              <span>ZONA B | TABLE 5</span>
            </div>
            <div className="step3__container__dropDown">
              <DropdownComponent
                // className="navbar_button"
                dropdownManual="navbar__dropdown"
                width={303}
                placeholder="select your drinks"
                onChange={(value, option) => {
                  setFieldValue("order.drinks", [
                    ...(values?.drinks || []),
                    value,
                  ]);
                  setSelectedDrinks((prevState) => [...prevState, option]);
                }}
                touched={touched?.drinks}
                errors={errors?.drinks}
                options={drinks}
              />
            </div>
            <span className="step3__container__minimum">
              minimum buy for this table is 500€
            </span>
          </div>
          {values?.drinks?.length > 0 && (
            <div className="step3__divs__table">
              <StepTableComponent />
            </div>
          )}
        </div>
      </div>
      <div style={{ width: "100%" }}>
        <div className="step3__container__button">
          <ButtonComponent
            label="confirm and pay"
            className="bookingPage__steper__button"
            onClick={handleContinue}
          />
        </div>
      </div>
    </div>
  );
};

export default Step3;
