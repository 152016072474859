import React from 'react';
import '../../../assets/scss/components/zone-web/ContactForm.scss'
const ContactForm = () => {
  return (
    <div className="contact-form-container">
      <div className="contact-form-content">
        <h1 className='contact-form-header'>Contact us</h1>
        <div className="contact-form">
          <div className="form-row">
            <div className="form-col1">
              <input type="text" placeholder="First Name" className="form-input" />
              <input type="text" placeholder="Last Name" className="form-input" />
              <input type="tel" placeholder="Phone Number" className="form-input" />
              <input type="email" placeholder="Email" className="form-input" />
            </div>
            <div className="form-col2">
              <textarea placeholder="Leave message..." className="form-textarea"></textarea>
              <button className="form-button">Send Message</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactForm;
