import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AdminLayout from "../../src/components/admin-layout";
import Dashboard from "../../src/pages/dashboard/Dashboard";
import Login from "../../src/pages/login";
import { useState } from "react";
import ProtectedRoutes from "../../src/routes/protectedRoutes";
import { fetchData } from "../services/fetch";
import { ENDPOINTS } from "../utils/endpoint";
import { useSetRecoilState } from "recoil";
import { userState } from "../utils/atoms";
import Tables from "../pages/tables/Tables";
import Events from "../pages/events/Events";
import Products from "../pages/products/Products";
import Subscriptions from "../pages/subscriptions/Subscriptions";
import Header from "../zone-web/components/header";
import Homepage from "../zone-web/homepage";
import EventsPage from "../zone-web/pages/events";
import ImageGallery from "../zone-web/pages/gallery";
import Step6Final from "../zone-web/pages/booking/steper/Step6Final";
import XceedEvents from "../zone-web/pages/events/XceedEvents";
import Refund from '../zone-web/pages/policy/refund-policy'
import Privacy from '../zone-web/pages/policy/privacy-policy'

const Routess = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const setUser = useSetRecoilState(userState);

  const token = localStorage.getItem("token");

  // useEffect(() => {
  //   if (token) {
  //     setIsAuthenticated(true);
  //     fetchData(ENDPOINTS.FETCH_CURRENT_USER).then((data) => setUser(data));
  //   }
  //   setLoading(false);
  // }, [token]);

  // if (loading) {
  //   // TODO add here a loading page or modal or something
  //   return <div>loading....</div>;
  // }

  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Homepage />}></Route>
          {/*<Route path="/events" element={<EventsPage />}></Route>*/}
          <Route path="/events" element={<XceedEvents />}></Route>
          <Route path="/booking/:id" element={<Step6Final />}></Route>
          <Route path="/gallery" element={<ImageGallery />}></Route>
          <Route path="/header" element={<Header />}></Route>
          <Route path='/refund-policy' element={<Refund />}></Route>
          <Route path='/privacy-policy' element={<Privacy />}></Route>
          <Route path="/login" element={<Login />}></Route>
          <Route
            path="/dashboard"
            element={
              <ProtectedRoutes isAuthenticated={isAuthenticated}>
                <AdminLayout>
                  <Dashboard />
                </AdminLayout>
              </ProtectedRoutes>
            }
          ></Route>
          <Route
            path="/tables"
            element={
              <ProtectedRoutes isAuthenticated={isAuthenticated}>
                <AdminLayout>
                  <Tables />
                </AdminLayout>
              </ProtectedRoutes>
            }
          ></Route>
          {/* the path eventss has to change */}
          <Route
            path="/eventss"
            element={
              <ProtectedRoutes isAuthenticated={isAuthenticated}>
                <AdminLayout>
                  <Events />
                </AdminLayout>
              </ProtectedRoutes>
            }
          ></Route>
          <Route
            path="/products"
            element={
              <ProtectedRoutes isAuthenticated={isAuthenticated}>
                <AdminLayout>
                  <Products />
                </AdminLayout>
              </ProtectedRoutes>
            }
          ></Route>
          <Route
            path="/subscriptions"
            element={
              <ProtectedRoutes isAuthenticated={isAuthenticated}>
                <AdminLayout>
                  <Subscriptions />
                </AdminLayout>
              </ProtectedRoutes>
            }
          ></Route>
        </Routes>
      </Router>
    </>
  );
};

export default Routess;
