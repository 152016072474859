import React from "react";
import CrossChart from "./components/CrossChart";
import PieCharts from "./components/PieCharts";

const Dashboard = () => {
  return (
    <div className="dashboard__container">
      <div className="container__dashboard__header__left">
        <div className="cross__charts">
          <div className="pie__charts">
            <div>
              <span>Products</span>
              <PieCharts />
            </div>
            <div>
              <span>Events</span>
              <PieCharts />
            </div>
          </div>
          <div className="cross__charts__chart">
            <CrossChart />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
