import React, { useEffect, useMemo, useState } from "react";
import MaterialReactTable from "material-react-table";
import { ReactComponent as EditIcon } from "../../assets/icons/editIcon.svg";
import { ReactComponent as DeleteIcon } from "../../assets/icons/deleteIcon.svg";
import { useNavigate } from "react-router-dom";
import axios from "../../utils/axios";
import { useRecoilState } from "recoil";
import { modalState } from "../../utils/atoms";
import { ENDPOINTS } from "../../utils/endpoint";
import { fetchData } from "../../services/fetch";
import ModalComponent from "../../components/modal";
import ButtonComponent from "../../components/button";
import { toast } from "react-toastify";

const Subscriptions = ({ handleTabs, activetab }) => {
  const [isModalOpen, setIsModalOpen] = useRecoilState(modalState);
  const [data, setData] = useState([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const handleEdit = (id) => {
    setIsModalOpen(true);
  };

  const handleDeleteButton = (row) => {
    setDeleteModalOpen(true);
    setSelectedRow(row.original.id);
  };

  const handleCancelButton = () => {
    setSelectedRow(null);
    setDeleteModalOpen(false);
  };

  const handleDelete = (id) => {
    axios
      .delete(`/inspection/${id}`)
      .then(() => {
        const updatedList = data.filter((item) => item.id !== id);
        setData(updatedList);
        setDeleteModalOpen(false);
        setSelectedRow(null);
        toast.success("Deleted Successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    fetchData(ENDPOINTS.FETCH_INSPECTION).then((data) => setData(data));
  }, []);

  const columns = useMemo(
    () => [
      {
        accessorKey: "userId",
        enableColumnFilter: false, // could disable just this column's filter
        header: "ID",
        size: 10,
      },
      {
        header: "Brand Id",
        accessorKey: "brandId", //simple accessorKey pointing to flat data
        size: 10,
      },
      {
        header: "Location Id",
        accessorKey: "locationId", //simple accessorKey pointing to flat data
        size: 10,
      },
      {
        header: "Status Id",
        accessorKey: "statusId", //simple accessorKey pointing to flat data
        size: 10,
      },
      {
        header: "Before Note",
        accessorKey: "beforeNote", //simple accessorKey pointing to flat data
      },
      {
        header: "After Note",
        accessorKey: "afterNote", //simple accessorKey pointing to flat data
      },

      {
        header: "Inspection Images",
        accessorKey: "inspectionImages", //simple accessorKey pointing to flat data
        Cell: ({ value }) => (
          <div>
            {value?.map((image, index) => (
              <div key={index}>
                <img src={image.url} alt="" />
                <div>Status: {image.status}</div>
              </div>
            ))}
          </div>
        ),
      },
      {
        header: "Actions",
        accessorKey: "actions",
        Cell: ({ row }) => (
          <>
            {/* <span
              style={{ cursor: "pointer", marginRight: "10px" }}
              onClick={() => handleEdit(row.original.id)}
            >
              <EditIcon />
            </span> */}
            <span
              style={{ cursor: "pointer" }}
              onClick={() => handleDeleteButton(row)}
            >
              <DeleteIcon />
            </span>
          </>
        ),
      },
    ],
    []
  );

  return (
    <div className="container">
      <ModalComponent
        isModalOpen={deleteModalOpen}
        setIsModalOpen={setDeleteModalOpen}
        positionModal="center"
        height="250px"
        width="400px"
      >
        <div
          style={{
            width: "100%",
            height: "200px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontFamily: "Poppins-SemiBold",
            fontSize: "20px",
          }}
        >
          Are you sure u want to delete
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontFamily: "Poppins-SemiBold",
            fontSize: "20px",
            gap: "5px",
          }}
        >
          <ButtonComponent
            className="createUser__container__button1"
            label="Cancel"
            onClick={handleCancelButton}
          />
          <ButtonComponent
            className="createUser__container__button2"
            label="Confirm"
            onClick={() => handleDelete(selectedRow)}
          />
        </div>
      </ModalComponent>
      <div className="materialTable__body">
        <MaterialReactTable
          search={true}
          columns={columns}
          data={data}
          muiTableHeadCellProps={{
            //simple styling with the `sx` prop, works just like a style prop in this example
            sx: {
              fontWeight: "normal",
              boxShadow: "none",
            },
          }}
          disableRowSelection
          // enableRowSelection={false} //enable some features
          enableColumnOrdering
          enableGlobalFilter //turn off a feature by setting to ={true} or ={false}
        />
      </div>
    </div>
  );
};

export default Subscriptions;
