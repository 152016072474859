import React, { useState } from "react";
import searchIcon from "../../../zone-assets/icons/searchIcon.svg";

const WebInput = ({
  className,
  placeholder,
  hasIcon = "false",
  handleSearch,
  searchValue,
  onChange,
}) => {
  const inputStyles = {
    border: "none",
    outline: "none",
    backgroundColor: "transparent",
    // color: "#fff",
    backgroundImage: hasIcon ? `url(${searchIcon})` : "none",
    // backgroundImage: `url(${searchIcon})`,
    backgroundPosition: "right 8px center",
    backgroundRepeat: "no-repeat",
    paddingLeft: "10px",
  };

  const handleChange = (e) => {
    handleSearch(e.target.value);
  };

  return (
    <>
      <input
        style={inputStyles}
        className={className}
        type="text"
        placeholder={placeholder}
        onChange={handleChange}
        handleSearch={hasIcon ? handleSearch : () => {}}
        value={searchValue}
      />
    </>
  );
};

export default WebInput;
