import React from "react";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";

const RenderSteps = (props) => {
  const {
    activeStep,
    handleContinue,
    values,
    setFieldValue,
    errors,
    touched,
  } = props;
  switch (activeStep) {
    case "zone":
      return (
        <Step2
          handleContinue={handleContinue}
          values={values?.zone}
          touched={touched?.zone}
          errors={errors?.zone}
          setFieldValue={setFieldValue}
        />
      );
    case "order":
      return (
        <Step3
          handleContinue={handleContinue}
          values={values?.order}
          touched={touched?.order}
          errors={errors?.order}
          setFieldValue={setFieldValue}
        />
      );
    case "payment":
      return (
        <Step4
          handleContinue={handleContinue}
        />
      );
    case "confirmation":
      return (
        <Step5
          handleContinue={handleContinue}
        />
      );
    default:
      return (
        <Step1
          handleContinue={handleContinue}
          values={values?.details}
          errors={errors?.details}
          touched={touched?.details}
          setFieldValue={setFieldValue}
        />
      );
  }
};

export default RenderSteps;
