import React from "react";
import xMenu from "../../../zone-assets/icons/xMenu.svg";
import { useNavigate } from "react-router-dom";

const Navbar = ({ isOpen, toggleSidebar }) => {
  const navigate = useNavigate();
  return (
    <div className={`navbar ${isOpen ? "open" : ""}`}>
      <div className="navbar__icon">
        <img
          style={{ width: "30px" }}
          src={xMenu}
          alt=""
          onClick={toggleSidebar}
        />
      </div>
      <div className="navbar__content">
        <ul className="navbar__content__ul">
          <li onClick={() => navigate("/")}>Home</li>
          <li onClick={() => navigate("/events")}>Events</li>
          {/* <li onClick={() => navigate("/")}>Venue</li> */}
          <li onClick={() => navigate("/gallery")}>Gallery</li>
          {/* <li onClick={() => navigate("/")}>Contact Us</li> */}
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
