import React from "react";
import { useRecoilValue } from "recoil";
import { bookingInformations } from "../../../../utils/atoms";

const StepCardComponent = () => {
  const bookingInfos = useRecoilValue(bookingInformations);
  const { event, name, surname, guests, email, phoneNumber, zone, table } =
    bookingInfos;
  return (
    <div className="stepCard__container">
      <div className="stepCard__container__eventName">
        <span className="stepCard__container__eventName__span1">event</span>
        <span className="stepCard__container__eventName__span2">{event}</span>
      </div>
      <div className="stepCard__container__nameSurname">
        <span className="stepCard__container__nameSurname__span1">
          name and surname
        </span>
        <span className="stepCard__container__nameSurname__span2">
          {name} {surname}
        </span>
      </div>
      <div className="stepCard__container__guests">
        <span className="stepCard__container__guests__span1">
          number of guests
        </span>
        <span className="stepCard__container__guests__span2">{guests}</span>
      </div>
      <div className="stepCard__container__contact">
        <span className="stepCard__container__contact__span1">contact</span>
        <span className="stepCard__container__contact__span2">
          {email} | {phoneNumber}
        </span>
      </div>
      <div className="stepCard__container__table">
        <span className="stepCard__container__table__span1">
          selected table
        </span>
        <span className="stepCard__container__table___span2">
          {zone} | {table}
        </span>
      </div>
    </div>
  );
};

export default StepCardComponent;
