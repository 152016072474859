import React, { useEffect, useState } from "react";
import ButtonComponent from "../button";
import InputComponent from "../input";
// import usersIcon from "../../assets/icons/userYellow.svg";
import zoneIconEvents from "../../zone-assets/icons/zoneIconEvents.svg";
import { Formik } from "formik";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import axios from "../../utils/axios";
import { toast } from "react-toastify";
import { fetchData } from "../../services/fetch";
import { ENDPOINTS } from "../../utils/endpoint";
import RadioButtonComponent from "../input/radioButton";
import { eventPercentage } from "../../utils/dummyData";
import PhotoUploader from "../input/uploader";
import eventValidationSchema from "../../validationSchemas/eventInputs";
import eventEditValidationSchema from "../../validationSchemas/eventInputsEdit";
import dayjs from "dayjs";
import CustomDatePicker from "../date-picker";
import RadioOption from "../input/radioButton";
import { eventInitialValue, eventsInitialValue } from "../../utils/atoms";

const CreateEvents = ({ setIsModalOpen }) => {
  const [event, setEvent] = useRecoilState(eventsInitialValue);
  const [eventInt, setEventInt] = useRecoilState(eventInitialValue);
  const [rolesData, setRoleData] = useState([]);

  useEffect(() => {
    return () => {
      setEvent({
        eventName: "",
        eventDate: "",
        imageUpload: null,
        percentage: "100",
        type: null,
      });
    };
  }, []);

  const handleSubmit = async (values) => {
    if (event?.id) {
      // let obj = { ...values, locationId: 1 };
      // delete [values?.phoneNumber, values?.location];
      axios
        .put(`/users/${event?.id}`, values)
        .then((res) => {
          setEvent(event);
        })
        .catch((err) => {});
      const arr = event.map((i) => {
        if (event?.id === i.id) {
          return values;
        } else {
          return i;
        }
      });
      setEvent(arr);
    } else {
      // let obj = { ...values };
      // delete obj.reEnterPassword;

      axios
        .post("/users", values)
        .then((res) => {
          setEvent(event);
          toast.success("The user has been successfully created", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        })
        .catch((err) => {});
    }
  };

  const handlePhotoUpload = (event, setFieldValue) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const imageURL = event.target.result;
      setFieldValue("imageUpload", imageURL);

      // Update the eventInitialValue atom with the selected image
      // setEventInt((prevEventInt) => ({
      //   ...prevEventInt,
      //   imageUpload: imageURL,
      // }));
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    fetchData(ENDPOINTS.FETCH_EVENTS).then((data) => {
      const extractedData = data.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      setEvent(extractedData);
    });
    fetchData(ENDPOINTS.FETCH_ROLES).then((data) => {
      const extractedData = data.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      setRoleData(extractedData);
    });
  }, []);

  const disablePastDates = (current) => {
    const today = dayjs();
    return current && today.isAfter(current, "day");
  };
  
  return (
    <>
      <Formik
        initialValues={eventInt}
        validationSchema={
          event.id ? eventEditValidationSchema : eventValidationSchema
        }
        onSubmit={(values, { resetForm }) => {
          handleSubmit(values);
          resetForm({ values: "" });
          setIsModalOpen(false);
        }}
        enableReinitialize={true}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <>
            <div className="createEvent__header">
              <img className="svgActive" src={zoneIconEvents} alt="" />
              {event.id ? "Edit event" : "Create new event"}
            </div>
            <form
              onSubmit={handleSubmit}
              autoComplete="new-password"
              noValidate
            >
              <div className="createEvent__container">
                <div className="createEvent__container__nameSurname">
                  <InputComponent
                    className="createEvent__name"
                    label="Event Name"
                    name="eventName"
                    onChange={(e) => setFieldValue("eventName", e.target.value)}
                    errors={errors.eventName}
                    touched={touched.eventName}
                    values={values.eventName}
                  />
                </div>
                <CustomDatePicker
                  onChange={(date) => {
                    const selectedDate = dayjs(date)?.valueOf();
                    setFieldValue("eventDate", selectedDate);
                  }}
                  disabledDate={disablePastDates}
                  errors={errors?.eventDate}
                  touched={touched?.eventDate}
                  showTime={{ hideDisabledOptions: true, format: "HH:mm" }}
                  className="createEvent__container-datepicker"
                />
                <div className="createEvent__container__upload">
                  {/* <InputComponent
                        className="createEvent__poster"
                        label="Event Poster"
                        name="eventPoster"
                        onChange={(e) =>
                          setFieldValue("eventPoster", e.target.value)
                        }
                        errors={errors.eventPoster}
                        touched={touched.eventPoster}
                        values={values.eventPoster}
                      /> */}
                  <span className="eventPosterName">Event Poster</span>
                  <div className="createEvent__container__uploader">
                    <PhotoUploader selectedPhoto={values.imageUpload} />
                    POSTER
                  </div>
                  {touched?.imageUpload && errors?.imageUpload && (
                    <span className="input_component__error">
                      {errors?.imageUpload}
                    </span>
                  )}
                  <label htmlFor="file__upload" className="upload__file">
                    Upload Poster
                  </label>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => {
                      handlePhotoUpload(e, setFieldValue);
                      // setFieldValue("imageUpload", e.target.files[0]);
                    }}
                    id="file__upload"
                    name="imageUpload"
                  />
                </div>
                <div className="createEvent__container__radioButton__both">
                  <div className="createEvent__container__radioButton">
                    <span>Increase default table prices</span>

                    <RadioOption
                      label={`${100} %`}
                      value="100"
                      name="percentage"
                      onChange={(value) => setFieldValue("percentage", value)}
                      checked={values.percentage === "100"}
                      className="createEvent__container__radioButton__aligns"
                    />
                    <RadioOption
                      label={`${50} %`}
                      value="50"
                      name="percentage"
                      onChange={(value) => setFieldValue("percentage", value)}
                      checked={values.percentage === "50"}
                      className="createEvent__container__radioButton__aligns"
                    />
                    <RadioOption
                      label={`${40} %`}
                      value="40"
                      name="percentage"
                      onChange={(value) => setFieldValue("percentage", value)}
                      checked={values.percentage === "40"}
                      className="createEvent__container__radioButton__aligns"
                    />
                    <RadioOption
                      label={`${20} %`}
                      value="20"
                      name="percentage"
                      onChange={(value) => setFieldValue("percentage", value)}
                      checked={values.percentage === "20"}
                      className="createEvent__container__radioButton__aligns"
                    />
                  </div>
                  <div className="createEvent__container__radioButton__type">
                    <span>Select type of event</span>

                    <RadioOption
                      label="Electronic"
                      value="electronic"
                      name="type"
                      onChange={(value) => setFieldValue("type", value)}
                      checked={values.type === "electronic"}
                      className="createEvent__container__radioButton__aligns"
                    />
                    <RadioOption
                      label="Dj"
                      value="dj"
                      name="type"
                      onChange={(value) => setFieldValue("type", value)}
                      checked={values.type === "dj"}
                      className="createEvent__container__radioButton__aligns"
                    />
                    <RadioOption
                      label="Live"
                      value="live"
                      name="type"
                      onChange={(value) => setFieldValue("type", value)}
                      checked={values.type === "live"}
                      className="createEvent__container__radioButton__aligns"
                    />
                  </div>
                </div>

                <div className="createEvent__container__buttons">
                  <ButtonComponent
                    className="createEvent__container__button1"
                    label="Cancel"
                    type="button"
                    onClick={() => setIsModalOpen(false)}
                  />
                  <ButtonComponent
                    className="createEvent__container__button2"
                    label={event.id ? "Update" : "Save Event"}
                    name="save"
                    type="submit"
                  />
                </div>
              </div>
            </form>
          </>
        )}
      </Formik>
    </>
  );
};

export default CreateEvents;
