import React, { useEffect, useMemo, useState } from "react";
import MaterialReactTable from "material-react-table";
import { ReactComponent as EditIcon } from "../../assets/icons/editIcon.svg";
import { ReactComponent as DeleteIcon } from "../../assets/icons/deleteIcon.svg";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  modalState,
  productInitialValue,
  productsInitialValue,
} from "../../utils/atoms";
import { ENDPOINTS } from "../../utils/endpoint";
import { fetchData } from "../../services/fetch";
import axios from "../../utils/axios";
import ModalComponent from "../../components/modal";
import ButtonComponent from "../../components/button";
import { toast } from "react-toastify";

const Products = () => {
  const setIsModalOpen = useSetRecoilState(modalState);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const products = useRecoilValue(productsInitialValue);
  const product = useRecoilValue(productInitialValue);
  const setProducts = useSetRecoilState(productsInitialValue);
  const setProduct = useSetRecoilState(productInitialValue);

  // const handleEdit = (id) => {
  //   setIsModalOpen(true);
  //   axios
  //     .get(`/statuses/${id}`)
  //     .then((res) => {
  //       setStatus(res.data);
  //     })
  //     .catch((err) => {
  //       <p>Cannot Receive Data</p>;
  //     });
  // };
  const handleEdit = (id) => {
    setIsModalOpen(true);
    setProduct(id);
  };

  const handleDelete = (id) => {
    axios
      .delete(`/products/${id}`)
      .then(() => {
        const updatedList = products.filter((item) => item.id !== id);
        setProducts(updatedList);
        setDeleteModalOpen(false);
        setSelectedRow(null);
        toast.success("Deleted Successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleDeleteButton = (row) => {
    setDeleteModalOpen(true);
    setSelectedRow(row.original.id);
  };

  const handleCancelButton = () => {
    setSelectedRow(null);
    setDeleteModalOpen(false);
  };

  useEffect(() => {
    fetchData(ENDPOINTS.FETCH_PRODUCTS).then((data) => setProducts(data));
  }, []);

  const columns = useMemo(
    () => [
      {
        header: "Product Name",
        accessorKey: "productName", //simple accessorKey pointing to flat data
      },
      {
        header: "Product Price",
        accessorKey: "productPrice", //simple accessorKey pointing to flat data
        // Cell: ({ row }) => (
        //   <div
        //     style={{
        //       border: "1px solid lightGray",
        //       borderRadius: "10%",
        //       display: "flex",
        //       alignItems: "center",
        //       justifyContent: "center",
        //       width: "30px",
        //       height: "30px",
        //     }}
        //   >
        //     <div
        //       style={{
        //         borderRadius: "10%",
        //         backgroundColor: row?.original?.color,
        //         width: "24px",
        //         height: "24px",
        //       }}
        //     ></div>
        //   </div>
        // ),
      },

      {
        header: "Actions",
        accessorKey: "actions",
        Cell: ({ row }) => (
          <>
            <span
              style={{ cursor: "pointer", marginRight: "10px" }}
              onClick={() => handleEdit(row.original)}
            >
              <EditIcon />
            </span>
            <span
              style={{ cursor: "pointer" }}
              onClick={() => handleDeleteButton(row)}
            >
              <DeleteIcon />
            </span>
          </>
        ),
      },
    ],
    [product]
  );

  return (
    <div className="container">
      <ModalComponent
        isModalOpen={deleteModalOpen}
        setIsModalOpen={setDeleteModalOpen}
        positionModal="center"
        height="250px"
        width="400px"
      >
        <div
          style={{
            width: "100%",
            height: "200px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontFamily: "Poppins-SemiBold",
            fontSize: "20px",
          }}
        >
          Are you sure u want to delete
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontFamily: "Poppins-SemiBold",
            fontSize: "20px",
            gap: "5px",
          }}
        >
          <ButtonComponent
            className="createUser__container__button1"
            label="Cancel"
            onClick={handleCancelButton}
          />
          <ButtonComponent
            className="createUser__container__button2"
            label="Confirm"
            onClick={() => handleDelete(selectedRow)}
          />
        </div>
      </ModalComponent>
      <div className="materialTable__body">
        <MaterialReactTable
          columns={columns}
          data={products}
          // enableRowSelection //enable some features
          disableRowSelection
          enableColumnOrdering
          enableGlobalFilter={true} //turn off a feature
        />
      </div>
    </div>
  );
};

export default Products;
