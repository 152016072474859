import React from "react";
import zoneVideo from "../../../zone-assets/video/zoneVideo3.mp4";

const Header = () => {
  return (
    <div className="web__header__container">
      <video
        autoPlay
        muted
        loop
        controls={false}
        playsInline
        className="web__header__container__video"
      >
        <source src={zoneVideo} type="video/mp4" />
      </video>
      {/* </div> */}
    </div>
  );
};

export default Header;
