import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import ImageGallery from "../eventsCards/imageGallery";
import EventsCard from "../eventsCards";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-cards";

// import required modules
import { EffectCards } from "swiper";

export const SliderComponent = ({ images, hasActions = false, width }) => {
  const showCardSlider = () => {
    return (
      <Swiper
        effect={"cards"}
        grabCursor={true}
        modules={[EffectCards]}
        className="mySwiper"
      >
        {images?.slice(0, 100).map((items) => (
          <SwiperSlide>
            <EventsCard
              name={items.name}
              date={items.date}
              day={items.day}
              image={items.image}
              ticketLink={items?.ticketLink}
              width={width}
              containerClass="eventsCard__containerClass"
            />
          </SwiperSlide>
        ))}
      </Swiper>
    );
  };
  const showDefaultSlider = () => {
    return (
      <Swiper
        breakpoints={{
          // when window width is >= 640px
          640: {
            width: 640,
            slidesPerView: 4,
          },
          // when window width is >= 768px
          768: {
            width: 768,
            slidesPerView: 2,
          },
        }}
        slidesPerView={1}
        loop={true}
        // centeredSlides={true}
        spaceBetween={30}
        grabCursor={true}
        pagination={{
          clickable: true,
        }}
        speed={1000}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        modules={[Autoplay, Pagination]}
        className="mySwiper"
      >
        {images?.slice(0, 15)?.map((items) => (
          <SwiperSlide>
            <ImageGallery
              name={items.name}
              date={items.date}
              day={items.day}
              image={items.src}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    );
  };

  return (
    <div id={`${hasActions ? "swiper-slider-default" : "swiper-slider-cards"}`}>
      {hasActions ? showDefaultSlider() : showCardSlider()}
    </div>
  );
};
