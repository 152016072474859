import React from "react";
import { useLocation } from "react-router-dom";
import Subscribe from "./subscribe";
import FooterNavbar from "./footerNavbar";
import ContactForm from "./ContactForm";

const Footer = () => {
  const location = useLocation();
  const isHomePage = location.pathname === "/";

  return (
    <div className="footerComponent__container">
      <Subscribe />
      {isHomePage && <ContactForm />}
      <FooterNavbar />
    </div>
  );
};

export default Footer;
