import React from "react";

const ButtonComponent = ({
  label,
  className,
  type = "button",
  onClick,
  icon,
  disabled,
  ref,
  style,
}) => {
  return (
    <button
      ref={ref}
      className={className}
      type={type}
      onClick={onClick}
      disabled={disabled}
    >
      {icon}
      <span>{label}</span>
    </button>
  );
};

export default ButtonComponent;
