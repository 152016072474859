import React from "react";
import ButtonComponent from "../../../components/button";
import cardImage from "../../../zone-assets/images/cardImage.png";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { showBookingModal } from "../../../utils/atoms";
import Booking from "../../pages/booking";

const EventsCard = ({
  name,
  date,
  day,
  image,
  classNameImg,
  className,
  mainDivClassName,
  width,
  contentClass,
  containerClass,
  imgClass,
  ticketLink,
}) => {
  // const navigate = useNavigate();
  // const setShowBookingModal = useSetRecoilState(showBookingModal);
  const navigateToWebsite = (ticketLink) => {
    window.open(ticketLink, "_blank");
  };

  return (
    <div
      style={{
        width: width,
      }}
      className={mainDivClassName}
    >
      <div className={`eventsCard__container ${containerClass}`}>
        <img
          src={image}
          alt=""
          className={`eventsCard__container__img ${imgClass}`}
        />
        <div className={`eventsCard__container__content ${contentClass}`}>
          <div className="eventsCard__container__content__nameAndDate">
            <div className="eventsCard__container__content__nameAndDate__name">
              {/* <span>{name}</span> */}
            </div>
            <div className="eventsCard__container__content__nameAndDate__date">
              {/* <span>{date}</span> | <span>{day}</span> */}
            </div>
          </div>
          <div className="eventsCard__container__content__button">
            <ButtonComponent
              label="Get Your Ticket"
              className="eventsCard__button"
              onClick={() => navigateToWebsite(ticketLink)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventsCard;
