import React, { useCallback, useEffect } from "react";
import zoneDrawer from "../../../zone-assets/icons/zoneLogoNavbar.svg";
import ButtonComponent from "../../../components/button";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
import { navbarTabs, showBookingModal } from "../../../utils/atoms";
import Booking from "../../pages/booking";

const NavbarDesktop = ({ hasBookButton = "false" }) => {
  const setShowBookingModal = useSetRecoilState(showBookingModal);
  const [activeTab, setActiveTab] = useRecoilState(navbarTabs);
  const navigate = useNavigate();
  const location = useLocation();

  const navigateToWebsite = (ticketLink) => {
    navigate('/events')
  };

  const tabs = ["Home", "Events", "Gallery"].map((tab) => ({
    tabName: tab === "Home" ? "" : tab.toUpperCase(),
    label: tab,
  }));

  const handleTabClick = useCallback(
    (tabName) => {
      navigate(`/${tabName.toLowerCase()}`);
      setActiveTab(tabName);
    },
    [navigate, setActiveTab]
  );

  useEffect(() => {
    // Update activeTab state based on the current location pathname
    const pathname = location.pathname.replace("/", "").toUpperCase();
    setActiveTab(pathname);
  }, [location.pathname, setActiveTab]);

  return (
    <div className="navbarDesktop__container">
      <div
        className="navbarDesktop__container__image"
        onClick={() => navigate("/")}
      >
        <img src={zoneDrawer} alt="" />
      </div>
      <div className="navbarDesktop__container__listAndButton">
        <div className="navbarDesktop__list">
          {/* <span
            className={`${activeTab === "HOME" ? "active" : ""}`}
            onClick={(e) => {
              navigate("/");
              setActiveTab(e.target.outerText);
            }}
          >
            home
          </span>
          <span
            className={`${activeTab === "EVENTS" ? "active" : ""}`}
            onClick={(e) => {
              navigate("/events");
              // setActiveTab("EVENTS");
              setActiveTab(e.target.outerText);
            }}
          >
            events
          </span> */}
          {tabs.map((tab) => (
            <span
              key={tab.tabName}
              className={`${activeTab === tab.tabName ? "active" : ""}`}
              onClick={() => handleTabClick(tab.tabName)}
            >
              {tab.label}
            </span>
          ))}
          {/* <span
            className={`${activeTab === "VENUE" ? "active" : ""}`}
            onClick={(e) => {
              navigate("/");
              setActiveTab(e.target.outerText);
            }}
          >
            venue
          </span> */}
          {/* <span
            className={`${activeTab === "GALLERY" ? "active" : ""}`}
            onClick={(e) => {
              navigate("/gallery");
              setActiveTab(e.target.outerText);
            }}
          >
            gallery
          </span> */}
          {/* <span
            className={`${activeTab === "CONTACT US" ? "active" : ""}`}
            onClick={(e) => {
              navigate("/");
              setActiveTab(e.target.outerText);
            }}
          >
            contact us
          </span> */}
        </div>
        {hasBookButton && (
          <div className="navbarDesktop__button">
            <ButtonComponent
              label="TICKETS & VIP"
              className="navbarDesktop__button1"
              onClick={() => navigateToWebsite()}
            />
          </div>
        )}
        <Booking />
      </div>
    </div>
  );
};

export default NavbarDesktop;
