import * as yup from "yup";

export const stepperSchema = yup.object().shape({
  details: yup.object().shape({
    event: yup.string().required("Please select an event"),
    name: yup.string().required("name is required"),
    surname: yup.string().required("surname is required"),
    guests: yup.number().required("Number of guests is required"),
    age: yup.number().required("Age is required"),
    email: yup.string().email().required("Email is required"),
    phoneNumber: yup
      .string()
      .matches(/^\+?[1-9]\d{1,14}$/, "Invalid phone number")
      .required("Phone number is required"),
  }),
  zone: yup.object().shape({
    zone: yup.string().required("Please select a zone"),
    table: yup.string().required("Please select a table"),
  }),
  order: yup.object().shape({
    drinks: yup
      .array()
      .min(1, "You must select at least 1 product")
      .required("Products are required"),
  }),
});

export const bookingInitialValues = {
  details: {
    event: "",
    name: "",
    surname: "",
    guests: "",
    age: 18,
    email: "",
    phoneNumber: "",
    comment: "",
  },
  zone: {
    zone: "",
    table: "",
  },
  order: {
    drinks: [],
  },
};
