import * as yup from "yup";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const productValidationSchema = yup.object().shape({
  productName: yup.string().required("Product name is required"),
  productPrice: yup.number().required("Product price is required"),
});

export default productValidationSchema;
