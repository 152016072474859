import React from "react";
import { useRecoilState } from "recoil";
import { selectedDrinksState } from "../../../../utils/atoms";

const StepTableComponent = () => {
  const [selectedDrinks] = useRecoilState(selectedDrinksState);
  const prices = selectedDrinks?.map((item) => item?.price);
  const totalPrice = prices?.[0] ? prices?.reduce((acc, el) => acc + el) : 0;
  return (
    selectedDrinks?.[0] && (
      <div className="stepTable__container">
        <div className="stepTable__container__table">
          {selectedDrinks.map((drinks, index) => (
            <div className="stepTable__container__table__items" key={index}>
              <div>{drinks?.name}</div>
              <div>${drinks?.price?.toString()}</div>
            </div>
          ))}
        </div>
        <div className="stepTable__container__total">
          <span>total</span>
          <span>{totalPrice}</span>
        </div>
      </div>
    )
  );
};

export default StepTableComponent;
