import img1 from "../zone-assets/gallery/1.jpg";
import img2 from "../zone-assets/gallery/2.jpg";
import img3 from "../zone-assets/gallery/3.jpg";
import img4 from "../zone-assets/gallery/4.jpg";
import img5 from "../zone-assets/gallery/5.jpg";
import img6 from "../zone-assets/gallery/6.jpg";
import img7 from "../zone-assets/gallery/7.jpg";
import img8 from "../zone-assets/gallery/8.jpg";
import img9 from "../zone-assets/gallery/9.jpg";
import img10 from "../zone-assets/gallery/10.jpg";
import img11 from "../zone-assets/gallery/11.jpg";
import img12 from "../zone-assets/gallery/12.jpg";
import img13 from "../zone-assets/gallery/13.jpg";
import img14 from "../zone-assets/gallery/14.jpg";
import img15 from "../zone-assets/gallery/15.jpg";
import img16 from "../zone-assets/gallery/16.jpg";
import img17 from "../zone-assets/gallery/17.jpg";
import img18 from "../zone-assets/gallery/18.jpg";
import img19 from "../zone-assets/gallery/19.jpg";
import img20 from "../zone-assets/gallery/20.jpg";
import img21 from "../zone-assets/gallery/21.jpg";
import img22 from "../zone-assets/gallery/22.jpg";
import img23 from "../zone-assets/gallery/23.jpg";
import img24 from "../zone-assets/gallery/24.jpg";
import img25 from "../zone-assets/gallery/25.jpg";
import img26 from "../zone-assets/gallery/26.jpg";
import img27 from "../zone-assets/gallery/27.jpg";
import img28 from "../zone-assets/gallery/28.jpg";
import img29 from "../zone-assets/gallery/29.jpg";
import img30 from "../zone-assets/gallery/30.jpg";
import img31 from "../zone-assets/gallery/31.jpg";
import img32 from "../zone-assets/gallery/32.jpg";

export const images = [
  {
    src: img1,
    indexImage: 1,
    original: img1,
    tags: [],
    width: 1875,
    height: 2500,
    caption: "",
  },
  {
    src: img2,
    indexImage: 2,
    original: img2,
    tags: [],
    width: 1875,
    height: 2500,
    caption: "",
  },
  {
    src: img3,
    indexImage: 3,
    original: img3,
    tags: [],
    width: 1875,
    height: 2500,
    caption: "",
  },
  {
    src: img4,
    indexImage: 4,
    original: img4,
    tags: [],
    width: 1875,
    height: 2500,
    caption: "",
  },
  {
    src: img5,
    indexImage: 5,
    original: img5,
    tags: [],
    width: 1875,
    height: 2500,
    caption: "",
  },
  {
    src: img6,
    indexImage: 6,
    original: img6,
    tags: [],
    width: 1875,
    height: 2500,
    caption: "",
  },
  {
    src: img7,
    indexImage: 7,
    original: img7,
    tags: [],
    width: 1875,
    height: 2500,
    caption: "",
  },
  {
    src: img8,
    indexImage: 8,
    original: img8,
    tags: [],
    width: 1875,
    height: 2500,
    caption: "",
  },
  {
    src: img9,
    indexImage: 9,
    original: img9,
    tags: [],
    width: 1875,
    height: 2500,
    caption: "",
  },
  {
    src: img10,
    indexImage: 10,
    original: img10,
    tags: [],
    width: 1875,
    height: 2500,
    caption: "",
  },
  {
    src: img11,
    indexImage: 11,
    original: img11,
    tags: [],
    width: 1875,
    height: 2500,
    caption: "",
  },
  {
    src: img12,
    indexImage: 12,
    original: img12,
    tags: [],
    width: 1875,
    height: 2500,
    caption: "",
  },
  {
    src: img13,
    indexImage: 13,
    original: img13,
    tags: [],
    width: 1875,
    height: 2500,
    caption: "",
  },
  {
    src: img14,
    indexImage: 14,
    original: img14,
    tags: [],
    width: 1875,
    height: 2500,
    caption: "",
  },
  {
    src: img15,
    indexImage: 15,
    original: img15,
    tags: [],
    width: 1875,
    height: 2500,
    caption: "",
  },
  {
    src: img16,
    indexImage: 16,
    original: img16,
    tags: [],
    width: 1875,
    height: 2500,
    caption: "",
  },
  {
    src: img17,
    indexImage: 17,
    original: img17,
    tags: [],
    width: 1875,
    height: 2500,
    caption: "",
  },
  {
    src: img18,
    indexImage: 18,
    original: img18,
    tags: [],
    width: 1875,
    height: 2500,
    caption: "",
  },
  {
    src: img19,
    indexImage: 19,
    original: img19,
    tags: [],
    width: 1875,
    height: 2500,
    caption: "",
  },
  {
    src: img20,
    indexImage: 20,
    original: img20,
    tags: [],
    width: 1875,
    height: 2500,
    caption: "",
  },
  {
    src: img21,
    indexImage: 21,
    original: img21,
    tags: [],
    width: 1875,
    height: 2500,
    caption: "",
  },
  {
    src: img22,
    indexImage: 22,
    original: img22,
    tags: [],
    width: 1875,
    height: 2500,
    caption: "",
  },
  {
    src: img23,
    indexImage: 23,
    original: img23,
    tags: [],
    width: 1875,
    height: 2500,
    caption: "",
  },
  {
    src: img24,
    indexImage: 24,
    original: img24,
    tags: [],
    width: 1875,
    height: 2500,
    caption: "",
  },
  {
    src: img31,
    indexImage: 31,
    original: img31,
    tags: [],
    width: 1875,
    height: 2500,
    caption: "",
  },
  {
    src: img32,
    indexImage: 32,
    original: img32,
    tags: [],
    width: 1875,
    height: 2500,
    caption: "",
  },
  {
    src: img25,
    indexImage: 25,
    original: img25,
    tags: [],
    width: 1875,
    height: 2500,
    caption: "",
  },
  {
    src: img26,
    indexImage: 26,
    original: img26,
    tags: [],
    width: 1875,
    height: 2500,
    caption: "",
  },
  {
    src: img27,
    indexImage: 27,
    original: img27,
    tags: [],
    width: 1875,
    height: 2500,
    caption: "",
  },
  {
    src: img28,
    indexImage: 28,
    original: img28,
    tags: [],
    width: 1875,
    height: 2500,
    caption: "",
  },
  {
    src: img29,
    indexImage: 29,
    original: img29,
    tags: [],
    width: 1875,
    height: 2500,
    caption: "",
  },
  {
    src: img30,
    indexImage: 30,
    original: img30,
    tags: [],
    width: 1875,
    height: 2500,
    caption: "",
  },
];
// export const images = [
//   {
//     src: "https://zone-public-files.s3.eu-central-1.amazonaws.com/Foto-1.webp",
//     original:
//       "https://zone-public-files.s3.eu-central-1.amazonaws.com/Foto-1.webp",
//     width: 500,
//     height: 400,
//     tags: [],
//     caption: "After Rain (Jeshu John - designerspics.com)",
//   },
//   {
//     src: "https://zone-public-files.s3.eu-central-1.amazonaws.com/Foto+2.webp",
//     original:
//       "https://zone-public-files.s3.eu-central-1.amazonaws.com/Foto+2.webp",
//     width: 500,
//     height: 400,
//     tags: [],
//   },
//   {
//     src: "https://zone-public-files.s3.eu-central-1.amazonaws.com/Foto+3.webp",
//     original:
//       "https://zone-public-files.s3.eu-central-1.amazonaws.com/Foto+3.webp",
//     width: 500,
//     height: 400,
//     tags: [],
//   },
//   {
//     src: "https://zone-public-files.s3.eu-central-1.amazonaws.com/Foto+4.webp",
//     original:
//       "https://zone-public-files.s3.eu-central-1.amazonaws.com/Foto+4.webp",
//     width: 500,
//     height: 400,
//     tags: [],
//   },
//   {
//     src: "https://zone-public-files.s3.eu-central-1.amazonaws.com/Foto+5.webp",
//     original:
//       "https://zone-public-files.s3.eu-central-1.amazonaws.com/Foto+5.webp",
//     width: 500,
//     height: 400,
//     tags: [
//       { value: "Nature", title: "Nature" },
//       { value: "Flora", title: "Flora" },
//     ],
//   },
//   {
//     src: "https://zone-public-files.s3.eu-central-1.amazonaws.com/Foto+6.webp",
//     original:
//       "https://zone-public-files.s3.eu-central-1.amazonaws.com/Foto+6.webp",
//     width: 500,
//     height: 400,
//     tags: [
//       { value: "Nature", title: "Nature" },
//       { value: "Flora", title: "Flora" },
//     ],
//   },
//   {
//     src: "https://zone-public-files.s3.eu-central-1.amazonaws.com/Foto+7.webp",
//     original:
//       "https://zone-public-files.s3.eu-central-1.amazonaws.com/Foto+7.webp",
//     width: 500,
//     height: 400,
//     tags: [
//       { value: "Nature", title: "Nature" },
//       { value: "Flora", title: "Flora" },
//     ],
//   },
//   {
//     src: "https://zone-public-files.s3.eu-central-1.amazonaws.com/Foto+8.webp",
//     original:
//       "https://zone-public-files.s3.eu-central-1.amazonaws.com/Foto+8.webp",
//     width: 500,
//     height: 400,
//     tags: [
//       { value: "Nature", title: "Nature" },
//       { value: "Flora", title: "Flora" },
//     ],
//   },
//   {
//     src: "https://zone-public-files.s3.eu-central-1.amazonaws.com/Foto+9.webp",
//     original:
//       "https://zone-public-files.s3.eu-central-1.amazonaws.com/Foto+9.webp",
//     width: 500,
//     height: 400,
//     tags: [
//       { value: "Nature", title: "Nature" },
//       { value: "Flora", title: "Flora" },
//     ],
//   },
//   {
//     src: "https://zone-public-files.s3.eu-central-1.amazonaws.com/Foto-10.webp",
//     original:
//       "https://zone-public-files.s3.eu-central-1.amazonaws.com/Foto-10.webp",
//     width: 500,
//     height: 400,
//     tags: [
//       { value: "Nature", title: "Nature" },
//       { value: "Flora", title: "Flora" },
//     ],
//   },
//   {
//     src: "https://zone-public-files.s3.eu-central-1.amazonaws.com/Foto-11.webp",
//     original:
//       "https://zone-public-files.s3.eu-central-1.amazonaws.com/Foto-11.webp",
//     width: 500,
//     height: 400,
//     tags: [
//       { value: "Nature", title: "Nature" },
//       { value: "Flora", title: "Flora" },
//     ],
//   },
//   {
//     src: "https://zone-public-files.s3.eu-central-1.amazonaws.com/Foto-12.webp",
//     original:
//       "https://zone-public-files.s3.eu-central-1.amazonaws.com/Foto-12.webp",
//     width: 500,
//     height: 400,
//     tags: [
//       { value: "Nature", title: "Nature" },
//       { value: "Flora", title: "Flora" },
//     ],
//   },
//   {
//     src: "https://zone-public-files.s3.eu-central-1.amazonaws.com/Foto-13.webp",
//     original:
//       "https://zone-public-files.s3.eu-central-1.amazonaws.com/Foto-13.webp",
//     width: 500,
//     height: 400,
//     tags: [
//       { value: "Nature", title: "Nature" },
//       { value: "Flora", title: "Flora" },
//     ],
//   },
//   {
//     src: "https://zone-public-files.s3.eu-central-1.amazonaws.com/Foto-14.webp",
//     original:
//       "https://zone-public-files.s3.eu-central-1.amazonaws.com/Foto-14.webp",
//     width: 500,
//     height: 400,
//     tags: [
//       { value: "Nature", title: "Nature" },
//       { value: "Flora", title: "Flora" },
//     ],
//   },
//   {
//     src: "https://zone-public-files.s3.eu-central-1.amazonaws.com/Foto-15.webp",
//     original:
//       "https://zone-public-files.s3.eu-central-1.amazonaws.com/Foto-15.webp",
//     width: 500,
//     height: 400,
//     tags: [
//       { value: "Nature", title: "Nature" },
//       { value: "Flora", title: "Flora" },
//     ],
//   },
//   {
//     src: "https://zone-public-files.s3.eu-central-1.amazonaws.com/Foto-16.webp",
//     original:
//       "https://zone-public-files.s3.eu-central-1.amazonaws.com/Foto-16.webp",
//     width: 500,
//     height: 400,
//     tags: [],
//   },
//   {
//     src: "https://zone-public-files.s3.eu-central-1.amazonaws.com/Foto-17.webp",
//     original:
//       "https://zone-public-files.s3.eu-central-1.amazonaws.com/Foto-17.webp",
//     width: 500,
//     height: 400,
//     tags: [],
//   },
//   {
//     src: "https://zone-public-files.s3.eu-central-1.amazonaws.com/Foto-18.webp",
//     original:
//       "https://zone-public-files.s3.eu-central-1.amazonaws.com/Foto-18.webp",
//     width: 500,
//     height: 400,
//     tags: [],
//   },
//   {
//     src: "https://zone-public-files.s3.eu-central-1.amazonaws.com/Foto+19.webp",
//     original:
//       "https://zone-public-files.s3.eu-central-1.amazonaws.com/Foto+19.webp",
//     width: 500,
//     height: 400,
//     tags: [],
//   },
//   {
//     src: "https://zone-public-files.s3.eu-central-1.amazonaws.com/Foto+20.webp",
//     original:
//       "https://zone-public-files.s3.eu-central-1.amazonaws.com/Foto+20.webp",
//     width: 500,
//     height: 400,
//     tags: [],
//   },
//   {
//     src: "https://zone-public-files.s3.eu-central-1.amazonaws.com/Foto+21.webp",
//     original:
//       "https://zone-public-files.s3.eu-central-1.amazonaws.com/Foto+21.webp",
//     width: 500,
//     height: 400,
//     tags: [],
//   },
//   {
//     src: "https://zone-public-files.s3.eu-central-1.amazonaws.com/Foto+22.webp",
//     original:
//       "https://zone-public-files.s3.eu-central-1.amazonaws.com/Foto+22.webp",
//     width: 500,
//     height: 400,
//     tags: [],
//   },
//   {
//     src: "https://zone-public-files.s3.eu-central-1.amazonaws.com/Foto+23.webp",
//     original:
//       "hhttps://zone-public-files.s3.eu-central-1.amazonaws.com/Foto+23.webp",
//     width: 500,
//     height: 400,
//     tags: [],
//   },
//   {
//     src: "https://zone-public-files.s3.eu-central-1.amazonaws.com/Foto+24.webp",
//     original:
//       "https://zone-public-files.s3.eu-central-1.amazonaws.com/Foto+24.webp",
//     width: 500,
//     height: 400,
//     tags: [],
//   },
//   {
//     src: "https://zone-public-files.s3.eu-central-1.amazonaws.com/Foto+25.webp",
//     original:
//       "https://zone-public-files.s3.eu-central-1.amazonaws.com/Foto+25.webp",
//     width: 500,
//     height: 400,
//     tags: [
//       { value: "Nature", title: "Nature" },
//       { value: "Flora", title: "Flora" },
//     ],
//   },
//   {
//     src: "https://zone-public-files.s3.eu-central-1.amazonaws.com/Foto+26.webp",
//     original:
//       "https://zone-public-files.s3.eu-central-1.amazonaws.com/Foto+26.webp",
//     width: 500,
//     height: 400,
//     tags: [
//       { value: "Nature", title: "Nature" },
//       { value: "Flora", title: "Flora" },
//     ],
//   },
//   {
//     src: "https://zone-public-files.s3.eu-central-1.amazonaws.com/Foto+27.webp",
//     original:
//       "https://zone-public-files.s3.eu-central-1.amazonaws.com/Foto+27.webp",
//     width: 500,
//     height: 400,
//     tags: [
//       { value: "Nature", title: "Nature" },
//       { value: "Flora", title: "Flora" },
//     ],
//   },
//   {
//     src: "https://zone-public-files.s3.eu-central-1.amazonaws.com/Foto+28.webp",
//     original:
//       "https://zone-public-files.s3.eu-central-1.amazonaws.com/Foto+28.webp",
//     width: 500,
//     height: 400,
//     tags: [],
//   },
//   {
//     src: "https://zone-public-files.s3.eu-central-1.amazonaws.com/Foto+29.webp",
//     original:
//       "https://zone-public-files.s3.eu-central-1.amazonaws.com/Foto+29.webp",
//     width: 500,
//     height: 400,
//     tags: [
//       { value: "Nature", title: "Nature" },
//       { value: "Flora", title: "Flora" },
//     ],
//   },
//   {
//     src: "https://zone-public-files.s3.eu-central-1.amazonaws.com/Foto+30.webp",
//     original:
//       "https://zone-public-files.s3.eu-central-1.amazonaws.com/Foto+30.webp",
//     width: 500,
//     height: 400,
//     tags: [
//       { value: "Nature", title: "Nature" },
//       { value: "Flora", title: "Flora" },
//     ],
//   },
//   {
//     src: "https://zone-public-files.s3.eu-central-1.amazonaws.com/Foto+31.webp",
//     original:
//       "https://zone-public-files.s3.eu-central-1.amazonaws.com/Foto+31.webp",
//     width: 500,
//     height: 400,
//     tags: [],
//   },
//   {
//     src: "https://zone-public-files.s3.eu-central-1.amazonaws.com/Foto+32.webp",
//     original:
//       "https://zone-public-files.s3.eu-central-1.amazonaws.com/Foto+32.webp",
//     width: 500,
//     height: 400,
//     tags: [
//       { value: "Nature", title: "Nature" },
//       { value: "Flora", title: "Flora" },
//     ],
//   },
// ];
