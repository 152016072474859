import "./App.css";
import Routes from "./routes/routes";
import "./assets/scss/index.scss";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "./zone-web/components/footer";

function App() {
  return (
    <>
      <Routes />
      {/* <Footer /> */}
      {/* <ToastContainer /> */}
    </>
  );
}

export default App;
