import { atom } from "recoil";

export const modalState = atom({
  key: "isModalOpen",
  default: false,
});

export const userState = atom({
  key: "user",
  default: {
    firstName: "",
    lastName: "",
    id: null,
  },
});

// USER
export const usersInitialValue = atom({
  key: "usersInitialValue",
  default: [],
});

export const userInitialValue = atom({
  key: "userInitialValue",
  default: {
    email: "",
    firstName: "",
    lastName: "",
    locationId: "",
    password: "",
    reEnterPassword: "",
    phoneNumber: "",
    roleId: "",
  },
});

// LOCATION
export const productsInitialValue = atom({
  key: "productsInitialValue",
  default: [],
});
export const productInitialValue = atom({
  key: "productInitialValue",
  default: {
    productName: "",
    productPrice: "",
  },
});

//STATUSES
export const eventsInitialValue = atom({
  key: "eventsInitialValue",
  default: [],
});
export const eventInitialValue = atom({
  key: "eventInitialValue",
  default: {
    eventName: "",
    eventDate: "",
    imageUpload: null,
    percentage: "20",
  },
});

//BRANDS
export const brandsInitialValue = atom({
  key: "brandsInitialValue",
  default: {},
});
export const brandInitialValue = atom({
  key: "brandInitialValue",
  default: {
    name: "",
  },
});

//ActiveTab State Booking

export const activeStepState = atom({
  key: "activeStepState",
  default: "details",
});

export const selectedDrinksState = atom({
  key: "selectedDrinks",
  default: [],
});

export const bookingInformations = atom({
  key: "bookingInfos",
  default: {},
});

export const showBookingModal = atom({
  key: "showBookingModal",
  default: false,
});

// Navbar Tabs

export const navbarTabs = atom({
  key: "activeTab",
  default: "HOME",
});
