import React, { useEffect, useMemo, useState } from "react";
import MaterialReactTable from "material-react-table";
import { ReactComponent as EditIcon } from "../../assets/icons/editIcon.svg";
import { ReactComponent as DeleteIcon } from "../../assets/icons/deleteIcon.svg";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  modalState,
  eventInitialValue,
  eventsInitialValue,
} from "../../utils/atoms";
import { ENDPOINTS } from "../../utils/endpoint";
import { fetchData } from "../../services/fetch";
import ModalComponent from "../../components/modal";
import ButtonComponent from "../../components/button";
import axios from "../../utils/axios";
import { toast } from "react-toastify";

const Events = () => {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const events = useRecoilValue(eventsInitialValue);
  const event = useRecoilValue(eventInitialValue);
  const setEvents = useSetRecoilState(eventsInitialValue);
  const setEvent = useSetRecoilState(eventInitialValue);
  const setIsModalOpen = useSetRecoilState(modalState);

  const handleEdit = (id) => {
    setIsModalOpen(true);
    setEvent(id);

    // axios
    //   .get(`/locations/${id}`)
    //   .then((res) => {
    //     setLocations(res.data);
    //   })
    //   .catch((err) => {
    //     <p>Cannot Receive Data</p>;
    //   });
  };

  const handleDeleteButton = (row) => {
    setDeleteModalOpen(true);
    setSelectedRow(row.original.id);
  };

  const handleCancelButton = () => {
    setSelectedRow(null);
    setDeleteModalOpen(false);
  };

  const handleDelete = (id) => {
    axios
      .delete(`/locations/${id}`)
      .then(() => {
        const updatedList = events.filter((item) => item.id !== id);
        setEvents(updatedList);
        setDeleteModalOpen(false);
        setSelectedRow(null);
        toast.success("Deleted Successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    fetchData(ENDPOINTS.FETCH_LOCATION).then((data) => setEvents(data));
  }, []);

  const columns = useMemo(
    () => [
      {
        header: "Event Name",
        accessorKey: "eventName", //simple accessorKey pointing to flat data
      },

      {
        header: "Event Date",
        accessorKey: "eventDate", //simple accessorKey pointing to flat data
      },
      {
        header: "Event Poster",
        accessorKey: "eventPoster", //simple accessorKey pointing to flat data
      },

      {
        header: "Actions",
        accessorKey: "actions",
        Cell: ({ row }) => (
          <>
            <span
              style={{ cursor: "pointer", marginRight: "10px" }}
              onClick={() => handleEdit(row.original)}
            >
              <EditIcon />
            </span>
            <span
              style={{ cursor: "pointer" }}
              onClick={() => handleDeleteButton(row)}
            >
              <DeleteIcon />
            </span>
          </>
        ),
      },
    ],
    [event]
  );

  return (
    <div className="container">
      <ModalComponent
        isModalOpen={deleteModalOpen}
        setIsModalOpen={setDeleteModalOpen}
        positionModal="center"
        height="250px"
        width="400px"
      >
        <div
          style={{
            width: "100%",
            height: "200px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontFamily: "Poppins-SemiBold",
            fontSize: "20px",
          }}
        >
          Are you sure u want to delete
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontFamily: "Poppins-SemiBold",
            fontSize: "20px",
            gap: "5px",
          }}
        >
          <ButtonComponent
            className="createUser__container__button1"
            label="Cancel"
            onClick={handleCancelButton}
          />
          <ButtonComponent
            className="createUser__container__button2"
            label="Confirm"
            onClick={() => handleDelete(selectedRow)}
          />
        </div>
      </ModalComponent>
      <div className="materialTable__body">
        <MaterialReactTable
          columns={columns}
          data={events}
          // enableRowSelection //enable some features
          disableRowSelection
          enableColumnOrdering
          enableGlobalFilter={true} //turn off a feature
        />
      </div>
    </div>
  );
};

export default Events;
