import React, { useState } from "react";
import { ReactComponent as EyeShow } from "../../assets/icons/eyeShow.svg";
import { ReactComponent as EyeHide } from "../../assets/icons/hideEye.svg";

const PasswordInput = ({
  className,
  placeholder,
  label,
  onChange,
  values,
  touched,
  errors,
  type,
  autoComplete,
  onBlur,
  image,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const passwordType = showPassword ? "text" : "password";

  return (
    <div className="input_component">
      {label && <label>{label}</label>}
      <div className="input__password">
        <input
          className={className}
          placeholder={placeholder}
          onChange={onChange}
          type={passwordType}
          value={values}
          autoComplete={autoComplete}
          onBlur={onBlur}
          image={image}
        />
        <span className="eye__icon" onClick={togglePassword}>
          {showPassword ? <EyeHide /> : <EyeShow />}
        </span>
      </div>

      {touched && errors && (
        <span className="input_component__error">{errors}</span>
      )}
    </div>
  );
};

export default PasswordInput;
