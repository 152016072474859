import { useFormik } from "formik";
import React from "react";
import { stepperSchema,bookingInitialValues } from "../../../../validationSchemas/bookingSchema";
import RenderSteps from "./helper";

const SteperComponent = ({
  activeStep,
  setActiveStep,
  steper,
  setFinished,
}) => {
  const formik = useFormik({
    initialValues: bookingInitialValues,
    validationSchema: stepperSchema,
    onSubmit: (values) => {
      // console.log(values);
    },
  });
  const { values, setFieldValue, handleSubmit, errors, touched } = formik;
  const handleContinue = () => {
    const currentIndex = steper.indexOf(activeStep);
    const nextIndex = currentIndex + 1;
    if (nextIndex < steper.length) {
      const nextTab = steper[nextIndex];
      setActiveStep(nextTab);
      // setFinished(false);
    } else {
      setFinished(true);
      setActiveStep("details");
    }
  };

  return (
    <form onSubmit={handleSubmit} className="booking--form">
      <div className="steperComponent__steperDiv">
        <RenderSteps
          activeStep={activeStep}
          handleContinue={handleContinue}
          values={values}
          setFieldValue={setFieldValue}
          errors={errors}
          touched={touched}
        />
      </div>
    </form>
  );
};

export default SteperComponent;
