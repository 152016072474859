import { Modal } from "antd";
import { ReactComponent as CloseIcon } from "../../zone-assets/icons/closeModalIcon.svg";

import React from "react";

const rightStyle = {
  position: "fixed",
  top: 0,
  right: 0,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "flex-end",
};
const centerStyle = {
  // position: "absolute",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  // top: "28%",
  // left: "32%",
  // transform: "translate(-50%, -50%)",
};

const ModalComponent = ({
  setIsModalOpen,
  isModalOpen,
  children,
  height = "100vh",
  width = "300px",
  positionModal = "right",
  title,
  className = "",
  closable = true,
  afterClose,
  ignoreInlineStyles = false,
  // className = "ant-modal",
}) => {
  let styles = {
    bgcolor: "background.paper",
    border: "none",
    // boxShadow: 24,
    p: 4,
    outline: "none",
    // overflow: "auto",
    display: "block",
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const positionStyles = {
    center: centerStyle,
    right: rightStyle,
  };

  styles = { ...styles, ...positionStyles[positionModal] };

  return (
    <Modal
      title={title}
      open={isModalOpen}
      onOk={handleOk}
      className={className}
      onCancel={handleCancel}
      width={positionStyles === "right" && !ignoreInlineStyles ? "100%" : ""}
      style={!ignoreInlineStyles ? styles : {}}
      footer={null}
      closable={closable}
      afterClose={afterClose}
      bodyStyle={!ignoreInlineStyles ? {
        height,
        width,
      }: {}}
      closeIcon={<CloseIcon />}
    >
      {children}
    </Modal>
  );
};

export default ModalComponent;
