import React, { useEffect, useState } from "react";
import hamburgerMenu from "../../../zone-assets/icons/hamburgerMenu.svg";
import zoneLogo from "../../../zone-assets/icons/zoneLogoNavbar.svg";
// import zoneLogo from "../../../zone-assets/icons/zoneLogo.svg";
import NavbarComponent from "./navbarComponent";
import ButtonComponent from "../../../components/button";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { showBookingModal } from "../../../utils/atoms";

const SidebarComponent = () => {
  const [isOpen, setIsOpen] = useState(false);
  const setShowBookingModal = useSetRecoilState(showBookingModal);
  const navigate = useNavigate();
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      const isScrolled = scrollTop > 0;
      setScrolled(isScrolled);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navigateToWebsite = (ticketLink) => {
    navigate('/events')
  };
  return (
    <div
      className={`homepage__components__navbar ${
        scrolled ? "inScroll" : "outScroll"
      }`}
    >
      <img
        onClick={toggleSidebar}
        src={hamburgerMenu}
        alt=""
        style={{ width: "30px", cursor: "pointer" }}
      />
      <img src={zoneLogo} alt="" style={{ width: "70px" }} />
      <div style={{ display: "flex", gap: "17px" }}>
        {/* <ButtonComponent
          className="homepage__components__button"
          label="get tickets"
        /> */}
        <ButtonComponent
          className="homepage__components__button"
          label="TICKETS & VIP"
          onClick={() => navigateToWebsite()}
        />
      </div>
      <NavbarComponent
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        toggleSidebar={toggleSidebar}
      />
    </div>
  );
};

export default SidebarComponent;
