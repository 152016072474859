import React from "react";
import "../../../assets/scss/components/zone-web/Privacy.scss";
import SidebarComponent from "../../components/navbar";
import NavbarDesktop from "../../components/navbar/navbarDesktop";
import FooterNavbar from "../../components/footer/footerNavbar";

const privacy = () => {
  return (
    <div className="privacy-container">
      <div className="navbar-desktop">
        <NavbarDesktop />
        <SidebarComponent />
      </div>
      <div className="text-area">
        <h1 className="text">
          <h1 className="text-bold">Privacy Policy for ZONE CLUB</h1>
          <br />
          Effective Date: JULY 2024
          <br />
          <br />
          1. Introduction
          <br />
          <br />
          Welcome to ZONE CLUB, located in Prishtina, Kosovo. We are committed
          to protecting your privacy and ensuring that your personal information
          is handled in a safe and responsible manner. This Privacy Policy
          outlines how we collect, use, and protect your information when you
          visit our website (www.zoneclub.al) and make use of our services.
          <br />
          <br />
          2. Information We Collect
          <br />
          <br />
          Personal Information: When you purchase tickets or reserve VIP tables,
          we may collect personal information such as your name, email address,
          phone number, and payment details.
          <br />
          Non-Personal Information: We may also collect non-personal information
          such as your browser type, IP address, and browsing behavior on our
          website.
          <br />
          <br />
          3. How We Use Your Information
          <br />
          <br />
          We use your personal information to:
          <br />
          <br />
          Process your ticket purchases and reservations.
          <br />
          Communicate with you about your bookings and provide customer support.
          <br />
          Send you promotional offers, newsletters, and event updates (if you
          have opted in to receive them).
          <br />
          Improve our website and services.
          <br />
          <br />
          4. Sharing Your Information
          <br />
          <br />
          We do not sell, trade, or otherwise transfer your personal information
          to outside parties, except as described below:
          <br />
          <br />
          Service Providers: We may share your information with third-party
          service providers who assist us in operating our website, processing
          payments, and conducting our business.
          <br />
          Legal Requirements: We may disclose your information if required by
          law or in response to legal requests.
          <br />
          <br />
          5. Data Security
          <br />
          <br />
          We implement a variety of security measures to protect your personal
          information. However, no method of transmission over the internet or
          electronic storage is 100% secure, and we cannot guarantee absolute
          security.
          <br />
          <br />
          6. Your Rights
          <br />
          <br />
          You have the right to:
          <br />
          <br />
          Access and review the personal information we hold about you.
          <br />
          Request corrections to any inaccuracies in your personal information.
          <br />
          Request deletion of your personal information, subject to legal and
          contractual obligations.
          <br />
          <br />
          7. Cookies
          <br />
          <br />
          Our website uses cookies to enhance your browsing experience. You can
          choose to disable cookies through your browser settings, but this may
          affect the functionality of our website.
          <br />
          <br />
          8. Changes to This Privacy Policy
          <br />
          <br />
          We may update this Privacy Policy from time to time. Any changes will
          be posted on this page, and the effective date will be updated
          accordingly.
          <br />
          <br />
          9. Contact Us
          <br />
          <br />
          If you have any questions or concerns about this Privacy Policy,
          please contact us at:
          <br />
          <br />
          Phone: +383 46 600 444
        </h1>
      </div>
      <FooterNavbar />
    </div>
  );
};

export default privacy;
