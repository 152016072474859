import React, { useEffect, useMemo, useState } from "react";
import MaterialReactTable from "material-react-table";
import { ReactComponent as EditIcon } from "../../assets/icons/editIcon.svg";
import { ReactComponent as DeleteIcon } from "../../assets/icons/deleteIcon.svg";
import { useSetRecoilState } from "recoil";
import { modalState, userInitialValue } from "../../utils/atoms";
import axios from "../../utils/axios";
import { fetchData } from "../../services/fetch";
import { ENDPOINTS } from "../../utils/endpoint";
import ModalComponent from "../../components/modal";
import ButtonComponent from "../../components/button";
import { toast } from "react-toastify";

const Tables = () => {
  const [data, setData] = useState([]);
  const setIsModalOpen = useSetRecoilState(modalState);
  const setUser = useSetRecoilState(userInitialValue);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const handleEdit = (id) => {
    setIsModalOpen(true);
    setUser(id);
    // get user by id request
    //   fetchData(`${ENDPOINTS.FETCH_USERS}/${user?.id}`).then((data) =>
    //   setUser(data)
    // );
  };

  const handleDeleteButton = (row) => {
    setDeleteModalOpen(true);
    setSelectedRow(row.original.id);
  };

  const handleCancelButton = () => {
    setSelectedRow(null);
    setDeleteModalOpen(false);
  };

  const handleDelete = (id) => {
    axios
      .delete(`/users/${id}`)
      .then(() => {
        const updatedList = data.filter((item) => item.id !== id);
        setData(updatedList);
        setDeleteModalOpen(false);
        setSelectedRow(null);
        toast.success("Deleted Successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    fetchData(ENDPOINTS.FETCH_USERS).then((data) => setData(data));
  }, []);

  const columns = useMemo(
    () => [
      {
        header: "Actions",
        accessorKey: "actions",
        Cell: ({ row }) => (
          <>
            <span
              style={{ cursor: "pointer", marginRight: "10px" }}
              onClick={() => handleEdit(row.original)}
            >
              <EditIcon />
            </span>
            <span
              style={{ cursor: "pointer" }}
              onClick={() => handleDeleteButton(row)}
            >
              <DeleteIcon />
            </span>
          </>
        ),
      },
      {
        accessorKey: "id",
        enableColumnFilter: false, // could disable just this column's filter
        header: "ID",
        size: 10,
      },
      {
        header: "First Name",
        accessorKey: "firstName", //simple accessorKey pointing to flat data
      },
      {
        header: "Last Name",
        accessorKey: "lastName", //simple accessorKey pointing to flat data
      },
      {
        header: "Email",
        accessorKey: "email", //simple accessorKey pointing to flat data
      },
      {
        header: "Phone Number",
        accessorKey: "phoneNumber", //simple accessorKey pointing to flat data
      },
      {
        header: "User Role",
        accessorKey: "roleId", //simple accessorKey pointing to flat data
      },
      {
        header: "Location",
        accessorKey: "location", //simple accessorKey pointing to flat data
      },
    ],
    [data]
  );

  return (
    <div className="container">
      <ModalComponent
        isModalOpen={deleteModalOpen}
        setIsModalOpen={setDeleteModalOpen}
        positionModal="center"
        height="250px"
        width="400px"
      >
        <div
          style={{
            width: "100%",
            height: "200px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontFamily: "Poppins-SemiBold",
            fontSize: "20px",
          }}
        >
          Are you sure u want to delete
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontFamily: "Poppins-SemiBold",
            fontSize: "20px",
            gap: "5px",
          }}
        >
          <ButtonComponent
            className="createUser__container__button1"
            label="Cancel"
            onClick={handleCancelButton}
          />
          <ButtonComponent
            className="createUser__container__button2"
            label="Confirm"
            onClick={() => handleDelete(selectedRow)}
          />
        </div>
      </ModalComponent>
      <div className="materialTable__body">
        <MaterialReactTable
          columns={columns}
          data={data}
          muiTableHeadCellProps={{
            //simple styling with the `sx` prop, works just like a style prop in this example
            sx: {
              fontWeight: "normal",
              boxShadow: "none",
            },
          }}
          // enableRowSelection //enable some features
          disableRowSelection
          enableColumnOrdering
          enableGlobalFilter={true} //turn off a feature
        />
      </div>
    </div>
  );
};

export default Tables;
