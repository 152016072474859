import React from "react";
import ButtonComponent from "../../../../components/button";
import StepTableComponent from "./StepTableComponent";
import StepCardComponent from "./StepCardComponent";
import confirmedIcon from "../../../../zone-assets/icons/confirmedIcon.svg";
import zoneLogo from "../../../../zone-assets/icons/zoneLogo.svg";

const Step6Final = ({ handleConfirm }) => {
  return (
    <div className="step6__container">
      <div className="step6__container__header">
        <div className="step6__container__header__imgAndText__container">
          <div className="step6__container__header__imgAndText">
            <img style={{ width: "112px" }} src={zoneLogo} alt="" />
            <div className="step6__container__header__imgAndText__text">
              <span className="step6__container__header__imgAndText__text__span1">
                Find below your confirmed reservation
              </span>
              <span className="step6__container__header__imgAndText__text__span2">
                show your QR code upon entry
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="step6__downContents">
        <div className="step6__container__items">
          <div className="step6__container__items__left">
            <div className="step6__container__items__left__eventName">
              <StepCardComponent />
            </div>
          </div>
          <div className="step6__container__items__right">
            <div className="step6__container__items__right__confirmCard">
              <img src={confirmedIcon} alt="" />
              payment confirmed
            </div>
            <div className="step6__container__items__right__confirmEmail">
              <span>
                confirmation email was sent to you containing the details
              </span>
            </div>
            <div className="step6__container__items__right__table">
              <StepTableComponent />
            </div>
          </div>
        </div>
      </div>
      <div className="step6__container__button">
        <ButtonComponent
          label="see you in zone"
          className="bookingPage__steper__button"
          onClick={handleConfirm}
        />
      </div>
    </div>
  );
};

export default Step6Final;
