import React, { useEffect, useState } from "react";
import Footer from "../../components/footer";
import SidebarComponent from "../../components/navbar";
import NavbarDesktop from "../../components/navbar/navbarDesktop";
import { images } from "../../../utils/zoneGalleryImages";
import PhotoSwipeLightbox from "photoswipe/lightbox";
import "photoswipe/style.css";
import "lazysizes";
import "lazysizes/plugins/parent-fit/ls.parent-fit";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

const ImageGallery = () => {
  useEffect(() => {
    let lightbox = new PhotoSwipeLightbox({
      gallery: "#gallery--perma-preloader",
      children: "a",
      pswpModule: () => import("photoswipe"),
      mainClass: "pswp-with-perma-preloader",
    });

    lightbox.init();

    return () => {
      lightbox.destroy();
      lightbox = null;
    };
  }, []);

  const [columns, setColumns] = useState(1);

  useEffect(() => {
    const updateColumns = () => {
      const width = window.innerWidth;
      setColumns(width < 600 ? 2 : width < 900 ? 3 : 4);
    };

    window.addEventListener('resize', updateColumns);
    updateColumns();

    return () => window.removeEventListener('resize', updateColumns);
  }, []);

  const partitionImages = (images, columns) => {
    const columnImages = Array.from({ length: columns }, () => []);
    images.forEach((image, i) => columnImages[i % columns].push(image));
    return columnImages;
  };

  const [index, setIndex] = useState(-1);
  return (
    <div className="galleryPage__container">
      <div className="navbarDesktop__gallery">
        <NavbarDesktop />
      </div>
      <div className="galleryPage__sidebar">
        <SidebarComponent />
      </div>
      <div
        style={{
          margin: "0 auto",
          width: "90%",
        }}
      >
        <div className="galleryPage__container__homeGallery__content">
          <div className="galleryPage__container__homeGallery__content__container">
            <span className="galleryPage__container__homeGallery__content__container__span1">
              unforgettable nights
            </span>
            <span className="galleryPage__container__homeGallery__content__container__span2">
              · it's not just about the music, it's all about the experience
            </span>
          </div>
        </div>
        <div className="masonry-layout" style={{ '--columns': columns }} id={"gallery--perma-preloader"} >
          {partitionImages(images, columns).map((column, cix) => {
            return (
              <div className={`masonry-item ${cix} ${column.length}`}>
                {
                  column.map((image, i) =>
                    <img src={image.src} alt="" className="test" key={i} onClick={() => {
                      setIndex(image.indexImage)
                    }} />
                  )
                }
              </div>
            )
          }
          )}
          <Lightbox
            slides={images}
            open={index >= 0}
            index={index - 1}
            close={() => setIndex(-1)}
          />
        </div>
      </div>
      <div className="zoneData__container">
        <Footer />
      </div>
    </div >
  );
};

export default ImageGallery;