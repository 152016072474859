import React from "react";
import { DatePicker } from "antd";

const RangePickerComponent = (props) => {
  const {
    containerClass = "container__dashboard__header__left__container__first",
    rangePickerClass = "container__dashboard__header__left__first__date",
    onChange,
    defaultValue,
  } = props;
  const { RangePicker } = DatePicker;

  return (
    <div>
      <div className={containerClass}>
        <div className={rangePickerClass}>
          <RangePicker
            format="DD-MM-YYYY"
            onChange={onChange}
            defaultValue={defaultValue}
          />
        </div>
      </div>
    </div>
  );
};

export default RangePickerComponent;
