import React from "react";
import DropdownComponent from "../../../../components/dropdown";
import InputComponent from "../../../../components/input";
import ButtonComponent from "../../../../components/button";
import { useRecoilState } from "recoil";
import { bookingInformations } from "../../../../utils/atoms";
import { ages, events } from "./staticData";

const Step1 = ({ handleContinue, values, touched, errors, setFieldValue }) => {
  const [bookingInfos, setBookingInfos] = useRecoilState(bookingInformations);

  return (
    <div className="step1__container">
      <div className="step1__alls">
        <div className="step1__container__firstInput">
          <DropdownComponent
            className="navbar_button"
            dropdownManual="navbar__dropdown"
            options={events}
            onChange={(value) => {
              setFieldValue("details.event", value);
              setBookingInfos({
                ...bookingInfos,
                event: value,
              });
            }}
            // width={294}
            placeholder="select the event"
            touched={touched?.event}
            errors={errors?.event}
          />
        </div>
        <div className="step1__container__secondInput">
          <InputComponent
            inputDivClass="step1__container__secondInput__input1"
            placeholder="name"
            onChange={(e) => {
              setFieldValue("details.name", e.target.value);
              setBookingInfos({
                ...bookingInfos,
                name: e.target.value,
              });
            }}
            values={values?.name}
            errors={errors?.name}
            touched={touched?.name}
          />
          <InputComponent
            inputDivClass="step1__container__secondInput__input1"
            placeholder="surname"
            values={values?.surname}
            onChange={(e) => {
              setFieldValue("details.surname", e.target.value);
              setBookingInfos({
                ...bookingInfos,
                surname: e.target.value,
              });
            }}
            errors={errors?.surname}
            touched={touched?.surname}
          />
        </div>
        <div className="step1__container__thirdInput">
          <InputComponent
            className="step1__inputOne"
            inputDivClass="step1__input1"
            placeholder="number of guests"
            values={values?.guests}
            onChange={(e) => {
              setFieldValue("details.guests", e.target.value);
              setBookingInfos({
                ...bookingInfos,
                guests: e.target.value,
              });
            }}
            errors={errors?.guests}
            touched={touched?.guests}
          />
          <DropdownComponent
            className="navbar_button"
            dropdownManual="navbar__dropdown"
            // width={294}
            options={ages}
            placeholder="estimated age"
            onChange={(value) => {
              setFieldValue("details.age", value);
              setBookingInfos({
                ...bookingInfos,
                age: value,
              });
            }}
            defaultValue={values?.age}
            touched={touched?.age}
            errors={errors?.age}
          />
        </div>
        <div className="step1__container__fourthInput">
          <InputComponent
            className="step1__inputTwo"
            inputDivClass="step1__input2"
            placeholder="email"
            values={values?.email}
            onChange={(e) => {
              setFieldValue("details.email", e.target.value);
              setBookingInfos({
                ...bookingInfos,
                email: e.target.value,
              });
            }}
            errors={errors?.email}
            touched={touched?.email}
          />
        </div>
        <div className="step1__container__fifthInput">
          <InputComponent
            className="step1__inputThree"
            inputDivClass="step1__input3"
            placeholder="phone number"
            values={values?.phoneNumber}
            onChange={(e) => {
              setFieldValue("details.phoneNumber", e.target.value);
              setBookingInfos({
                ...bookingInfos,
                phoneNumber: e.target.value,
              });
            }}
            errors={errors?.phoneNumber}
            touched={touched?.phoneNumber}
          />
        </div>
        <div className="step1__container__sixthInput">
          <InputComponent
            className="step1__inputFour"
            inputDivClass="step1__input4"
            placeholder="comment"
            values={values?.comment}
            onChange={(e) => {
              setFieldValue("details.comment", e.target.value);
              setBookingInfos({
                ...bookingInfos,
                comment: e.target.value,
              });
            }}
            touched={touched?.comment}
            errors={errors?.comment}
          />
        </div>
      </div>
      <div className="step1__container__button" style={{ marginTop: "40px" }}>
        <ButtonComponent
          label="continue"
          className="bookingPage__steper__button"
          onClick={handleContinue}
        />
      </div>
    </div>
  );
};

export default Step1;
