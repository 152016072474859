// export const ENDPOINTS = {
//   FETCH_USERS: "/users",
//   FETCH_CURRENT_USER: "/users/me",
//   FETCH_LOCATION: "/locations",
//   FETCH_INSPECTION: "/inspection",
//   FETCH_ROLES: "/roles",
//   FETCH_STATUSES: "/statuses",
//   FETCH_BRANDS: "/brands",
//   FETCH_LOGIN: "/login",
// };

export const ENDPOINTS = {
  FETCH_USERS: "/users",
  FETCH_CURRENT_USER: "/users/me",
  FETCH_PRODUCTS: "/products",
  FETCH_EVENTS: "/events",
  FETCH_TABLES: "/tables",
  FETCH_STATUSES: "/subscriptions",
  FETCH_LOGIN: "/login",
};
