import React, { useEffect, useRef, useState } from "react";
import SteperComponent from "./steper";
import oZoneStepper from "../../../zone-assets/icons/oZoneStepper.png";
import xIcon from "../../../zone-assets/icons/xIcon.svg";
import ButtonComponent from "../../../components/button";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { activeStepState, showBookingModal } from "../../../utils/atoms";
import Step6Final from "./steper/Step6Final";
import ModalComponent from "../../../components/modal";

const Booking = () => {
  const [activeStep, setActiveStep] = useRecoilState(activeStepState);
  const [showModal, setShowModal] = useRecoilState(showBookingModal);
  const [finished, setFinished] = useState(false);
  const steper = ["details", "zone", "order", "payment", "confirmation"];
  const navigate = useNavigate();
  const buttonContainerRef = useRef(null);
  const renderButtons = () => {
    return steper?.map((step, index) => {
      const isActive = step === activeStep;
      const renderLine = index > 0;
      return (
        <>
          {renderLine && <div className="connection-line" />}
          <ButtonComponent
            onClick={() => setActiveStep(step)}
            className={`steperButtons ${isActive ? "active" : ""}`}
            label={step}
          />
        </>
      );
    });
  };
  useEffect(() => {
    if (buttonContainerRef.current) {
      const activeButton = buttonContainerRef.current.querySelector(".active");
      activeButton && activeButton.scrollIntoView({ behavior: "smooth" });
    }
  }, [activeStep]);
  const afterClose = () => {
    setActiveStep("details");
  };
  const handleConfirm = () => {
    afterClose();
    setShowModal(false);
    setFinished(false);
  }

  return (
    <ModalComponent
      isModalOpen={showModal}
      setIsModalOpen={setShowModal}
      className="booking__modal"
      closable={false}
      afterClose={afterClose}
      ignoreInlineStyles={true}
    >
      <div className="bookingPage__container">
        <div className="bookingPage__modalContainer">
          <div className="bookingPage__modalContainer__modal">
            {finished ? (
              <Step6Final handleConfirm={handleConfirm}/>
            ) : (
              <div>
                <div
                  className="bookingPage__modalContainer__modal__x"
                  onClick={() => navigate("/")}
                >
                  <img src={xIcon} alt="" onClick={() => setShowModal(false)} />
                </div>
                <div className="bookingPage__modalContainer__modal__up">
                  <div className="bookingPage__modalContainer__modal__up__headerSteper">
                    <div className="bookingPage__modalContainer__modal__up__headerSteper__div">
                      <div className="bookingPage__modalContainer__modal__up__headerSteper__text">
                        <div className="bookingPage__modalContainer__modal__up__img">
                          <img src={oZoneStepper} alt="" />
                        </div>
                        <div className="bookingPage__modalContainer__modal__up__spans">
                          <span className="bookingPage__modalContainer__modal__up__span1">
                            Make your online reservation
                          </span>
                          <span className="bookingPage__modalContainer__modal__up__span2">
                            Finish the steps to confirm your reservation
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="bookingPage__modalContainer__modal__up__buttonSteper">
                      <div className="bookingPage__modalContainer__modal__up__buttonSteper__render"
                      ref={buttonContainerRef}
                      >
                        {renderButtons()}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bookingPage__modalContainer__modal__down">
                  <SteperComponent
                    activeStep={activeStep}
                    setActiveStep={setActiveStep}
                    steper={steper}
                    setFinished={setFinished}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </ModalComponent>
  );
};

export default Booking;
