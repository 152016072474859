import zoneIconDashboard from "../../../../zone-assets/icons/zoneIconDashboard.svg";
import zoneIconTables from "../../../../zone-assets/icons/zoneIconTables.svg";
import zoneIconEvents from "../../../../zone-assets/icons/zoneIconEvents.svg";
import zoneIconProducts from "../../../../zone-assets/icons/zoneIconProducts.svg";
import zoneIconSubscription from "../../../../zone-assets/icons/zoneIconSubscription.svg";
// import users from "../../../../assets/icons/usersIcon.svg";
// import locations from "../../../../assets/icons/locationsIcon.svg";
// import statuse from "../../../../assets/icons/statusIcon.svg";
// import analytics from "../../../../assets/icons/analyticsIcon.svg";
// import brandsIcon from "../../../../assets/icons/brandsIcon.svg";

export const drawerItems = [
  {
    name: "Dashboard",
    image: zoneIconDashboard,
    tab: "dashboard",
    //   buttonName: 'NEU',
    GET_REQUEST: "/dashboard",
    EDIT_LINK: "/dashboard/edit/:id",
    //   DELETE_LINK: '/admin/carrier/:carrierId',
  },
  {
    name: "Tables",
    tab: "tables",
    image: zoneIconTables,
    //   buttonName: 'Create New Single Career +',
    GET_REQUEST: "/users",
    //   EDIT_LINK: '/admin/appliers/edit/:id',
    //   DELETE_LINK: '/admin/appliers/:appliersId',
  },
  {
    name: "Events",
    image: zoneIconEvents,
    tab: "events",
    //   buttonName: 'NEU',
    // GET_REQUEST: "/eventss",
    //   EDIT_LINK: '/admin/users/edit/:id',
    //   DELETE_LINK: '/admin/users/:usersId',
  },
  {
    name: "Products",
    image: zoneIconProducts,
    tab: "products",
    //   buttonName: 'NEU',
    GET_REQUEST: "/products",
    //   EDIT_LINK: '/admin/users/edit/:id',
    //   DELETE_LINK: '/admin/users/:usersId',
  },
  {
    name: "Subscriptions",
    image: zoneIconSubscription,
    tab: "subscriptions",
    //   buttonName: 'NEU',
    GET_REQUEST: "/subscriptions",
    //   EDIT_LINK: '/admin/users/edit/:id',
    //   DELETE_LINK: '/admin/users/:usersId',
  },
];
