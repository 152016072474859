import React from "react";
import footerImage from "../../../zone-assets/images/footerImage.png";
import WebInput from "../inputComponent";

const Subscribe = () => {
  return (
    <div className="footer__container">
      <div className="footer__container__upperPart">
        {/* <img src={footerImage} alt="" /> */}
        <div className="footer__container__contents">
          <div className="footer__container__contents__text">
            <span>Subscribe and get notified for future events</span>
          </div>
          <div className="footer__container__contents__inputs">
            <WebInput
              className="footer__container__contents__input"
              placeholder="example@gmail.com"
              handleSearch={() => ({})}
            />
            <button>Subscribe</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Subscribe;
