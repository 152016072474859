import React from "react";

const RadioOption = ({ label, value, name, onChange, checked, className }) => {
  const handleChange = () => {
    onChange(value);
  };

  return (
    <div className={className}>
      <input
        type="radio"
        id={value}
        name={name}
        value={value}
        checked={checked}
        onChange={handleChange}
        style={{
          appearance: "none",
          width: "13px",
          height: "13px",
          borderRadius: "50%",
          border: "1px solid #c3c1c1",
          backgroundColor: checked ? "#ff0000" : "#fff",
          outline: "none",
          transition: "background-color 0.2s",
        }}
      />
      <label htmlFor={value}>{label}</label>
    </div>
  );
};

export default RadioOption;
