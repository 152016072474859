import React from "react";

const ImageGallery = ({ image }) => {
  return (
    // <div>
    <div className="imageGallery__container">
      <img src={image} alt="" className="imageGallery__container__img" />
    </div>
    // </div>
  );
};

export default ImageGallery;
