import DrawerComponent from "./components/drawer/DrawerComponent";
import Navbar from "./components/navbar/navbar";
import "./index.scss";
import { useState } from "react";
import { modalState } from "../../utils/atoms";
import { useRecoilState } from "recoil";

const AdminLayout = ({ children }) => {
  const [activeTab, setActiveTab] = useState("dashboard");
  const [isModalOpen, setIsModalOpen] = useRecoilState(modalState);

  return (
    <div className="adminLayout__container">
      <div className="adminLayout__left">
        <DrawerComponent setActiveTab={setActiveTab} activeTab={activeTab} />
      </div>
      <div className="adminLayout__container__navAndChildren">
        <div className="adminLayout__right">
          <Navbar
            activeTab={activeTab}
            setIsModalOpen={setIsModalOpen}
            isModalOpen={isModalOpen}
          />
        </div>
        <div className="adminLayout__children">{children}</div>
      </div>
    </div>
  );
};

export default AdminLayout;

// use A way dminLayout this<AdminLayout> children </AdminLayout>
