export const events = [
  { value: "event1", label: "Event 1" },
  { value: "event2", label: "Event 2" },
  { value: "event3", label: "Event 3" },
];
export const ages = [];
for (let i = 18; i <= 50; i++) {
  ages.push({ value: i.toString(), label: i.toString() });
}

export const drinks = [
  {
    value: "Jack Daniels",
    label: "Jack Daniels",
    name: "Jack Daniels",
    price: 56,
  },
  {
    value: "Grey Goose",
    label: "Grey Goose",
    name: "Grey Goose",
    price: 140,
  },
  {
    value: "Jägermeister",
    label: "Jägermeister",
    name: "Jägermeister",
    price: 370,
  },
  {
    value: "Jamison",
    name: "Jamison",
    label: "Jamison",
    price: 200,
  },
  {
    value: "Hendrick's Gin",
    label: "Hendrick's Gin",
    name: "Hendrick's Gin",
    price: 800,
  },
  {
    value: "Chardonnay",
    name: "Chardonnay",
    label: "Chardonnay",
    price: 200,
  },
  {
    value: "Jamison",
    label: "Jamison",
    name: "Jamison",
    price: 200,
  },
  {
    name: "Hendrick's Gin",
    value: "Hendrick's Gin",
    label: "Hendrick's Gin",
    price: 800,
  },
  {
    name: "Chardonnay",
    value: "Chardonnay",
    label: "Chardonnay",
    price: 200,
  },
  {
    name: "Jamison",
    value: "Jamison",
    label: "Jamison",
    price: 200,
  },
  {
    name: "Hendrick's Gin",
    value: "Hendrick's Gin",
    label: "Hendrick's Gin",
    price: 800,
  },
  {
    name: "Chardonnay",
    value: "Chardonnay",
    label: "Chardonnay",
    price: 200,
  },
];

export const tables = [
    { value: "table1", label: "Table 1" },
    { value: "table2", label: "Table 2" },
    { value: "table3", label: "Table 3" },
  ];