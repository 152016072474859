import React, { useState } from "react";
import { ReactComponent as DropdownArrow } from "../../zone-assets/icons/downRedArrow.svg";
import { Select, Space } from "antd";

const DropdownComponent = ({
  label,
  width,
  onChange,
  errors,
  touched,
  defaultValue,
  options,
  dropdownManual,
  placeholder,
}) => {
  return (
    <div className={`dropdown_component ${dropdownManual}`}>
      {label && <label>{label}</label>}
      {/* <Space size={10} style={{ width: "250px", cursor: "pointer !important" }}> */}
      <Select
        suffixIcon={<DropdownArrow />}
        controlHeight={45}
        onChange={onChange}
        placeholder={placeholder}
        style={{
          width,
        }}
        options={options}
        defaultValue={defaultValue}
      />
      {/* </Space> */}
      {touched && errors && (
        <span className="input_component__error">{errors}</span>
      )}
    </div>
  );
};

export default DropdownComponent;
