import React, { useEffect, useState } from "react";
import { ReactComponent as AddNew } from "../../../../zone-assets/icons/zonePlusIcon.svg";
import { ReactComponent as Export } from "../../../../assets/icons/exportYellow.svg";
import ButtonComponent from "../../../button";
import { drawerItems } from "../drawer/DrawerAdminItems";
import ModalComponent from "../../../modal";
import * as dayjs from "dayjs";
import Profile from "../../../../pages/dashboard/components/Profile";
import DropdownComponent from "../../../dropdown";
import { fetchData } from "../../../../services/fetch";
import { ENDPOINTS } from "../../../../utils/endpoint";
import { userState } from "../../../../utils/atoms";
import { useRecoilValue } from "recoil";
import CreateEvents from "../../../modal-inputs/CreateEventInput";
import CreateProducts from "../../../modal-inputs/CreateProductsInput";
import RangePickerComponent from "../../../range-picker";

const Navbar = ({ activeTab, setIsModalOpen, isModalOpen }) => {
  const [locationData, setLocationData] = useState([]);
  const [userData, setUserData] = useState([]);
  const currentUser = useRecoilValue(userState);

  // const [isModalOpen, setIsModalOpen] = useState(false);
  const [datesGraph, setDatesGraph] = useState({
    from: dayjs().startOf("month").valueOf(),
    to: dayjs().valueOf(),
  });

  useEffect(() => {
    fetchData(ENDPOINTS.FETCH_LOCATION).then((data) => {
      const extractedData = data.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      setLocationData(extractedData);
    });
    fetchData(ENDPOINTS.FETCH_USERS).then((data) => {
      const extractedData = data.map((item) => ({
        value: item.id,
        label: item.firstName,
      }));
      setUserData(extractedData);
    });
  }, []);

  return (
    <div className="navbar__container">
      <div className="navbar__container__content">
        <div className="navbar__container__left">
          <div>
            <img
              className="navbar__container__left__image"
              src={drawerItems.find((item) => item.tab === activeTab)?.image}
              alt=""
            />
          </div>
          <div>
            <span>
              {drawerItems.find((item) => item.tab === activeTab).name}
            </span>
          </div>
        </div>
        <div className="navbar__container__right">
          <RangePickerComponent
            onChange={(dates) =>
              setDatesGraph({
                ...datesGraph,
                from: dayjs(dates[0]).valueOf(),
                to: dayjs(dates[1]).valueOf(),
              })
            }
            defaultValue={[dayjs(datesGraph.from), dayjs(datesGraph.to)]}
          />

          {activeTab !== "tables" &&
            activeTab !== "subscriptions" &&
            activeTab !== "dashboard" && (
              <div>
                <ButtonComponent
                  dropdownManual="navbar__container__right__dropdown"
                  icon={<AddNew />}
                  label="ADD NEW"
                  className="navbar_button"
                  onClick={() => setIsModalOpen(true)}
                />
              </div>
            )}
          {activeTab === "analytics" && (
            <div className="navbar_buttons">
              <div className="navbar_buttons_locationUser">
                <DropdownComponent
                  className="navbar_button"
                  dropdownManual="navbar__dropdown"
                  options={locationData}
                  defaultValue="All"
                />
              </div>
              <DropdownComponent
                className="navbar_button"
                options={userData}
                dropdownManual="navbar__dropdown"
                defaultValue="All"
              />

              <ButtonComponent
                icon={<Export />}
                label="EXPORT"
                className="navbar_button__export"
              />
            </div>
          )}
          <Profile currentUser={currentUser} />
        </div>
      </div>
      <div className="bottom_border"></div>
      {isModalOpen && (
        <ModalComponent
          width="500px"
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        >
          {(() => {
            switch (activeTab) {
              case "events":
                return <CreateEvents setIsModalOpen={setIsModalOpen} />;
              case "products":
                return <CreateProducts setIsModalOpen={setIsModalOpen} />;
              default:
                return <></>;
            }
          })()}
        </ModalComponent>
      )}
    </div>
  );
};

export default Navbar;
